export function reset() {
  return {
    type: '@@CChess/reset' as const
  }
}

export function play(rowID: number, colID: number) {
  return {
    type: '@@CChess/play' as const,
    rowID,
    colID
  }
}

export function setSettingMode(){
  return {
    type: '@@CChess/setSettingMode' as const,
  }
}

export function setCheckReminder(boolean: boolean){
  return {
    type: '@@CChess/setCheckReminder' as const,
    boolean
  }
}

export function giveUp(){
  return {
    type: '@@CChess/giveUp' as const,
  }
}

export function withdrawal(){
  return {
    type: '@@CChess/withdrawal' as const,
  }
}

export function viewCChessHistory(id: number){
  return {
    type: '@@CChess/viewChessHistory' as const,
    id
  }
}

export function deleteCChessHistory(id: number){
  return {
    type: '@@CChess/deleteChessHistory' as const,
    id
  }
}

export function showRecordedChessArray(num: number){
  return {
    type: '@@CChess/showRecordedChessArray' as const,
    num
  }
}

export function makeDrawRequest(){
  return {
    type: '@@CChess/makeDrawRequest' as const,
  }
}

export function replyDrawRequest(){
  return {
    type: '@@CChess/replyDrawRequest' as const,
  }
}

export function init(){
  return {
    type: '@@CChess/init' as const,
  }
}

export type ICChessAction =
  | ReturnType<typeof reset>
  | ReturnType<typeof play>
  | ReturnType<typeof setSettingMode>
  | ReturnType<typeof setCheckReminder>
  | ReturnType<typeof giveUp>
  | ReturnType<typeof withdrawal>
  | ReturnType<typeof viewCChessHistory>
  | ReturnType<typeof deleteCChessHistory>
  | ReturnType<typeof showRecordedChessArray>
  | ReturnType<typeof makeDrawRequest>
  | ReturnType<typeof replyDrawRequest>
  | ReturnType<typeof init>