export type ILoginState = {
  loginStatus: boolean
  isAuthenticated: boolean
  msg: string
  user?: JWTPayload
  error?: string
}

export type JWTPayload = {
  user_id: number
  username: string
  role: string
}

export interface ILoginForm{
  username:string,
  password:string
}

export const initialState: ILoginState = {
  loginStatus: false,
  isAuthenticated: false,
  msg:""
}
