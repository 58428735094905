import { classes } from "../../Utility/class";


export default function GiveUp(
  props:{
    currentPlayer: string,
    drawMode: number,
    onClickAccept: ()=>void
    onClickReject: ()=>void
  }
){

  const currentPlayer = props.currentPlayer
  const drawMode = props.drawMode

  return (
    <div style={{position: 'absolute', top: 0, height: '100%', width: '100%', background: 'rgba(50, 50, 50, 0.8', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <h1 style={{color: 'white'}}>A draw request from {currentPlayer}.</h1>
        <p style={{color: 'white'}}>({(6 - drawMode - 1)/2} time(s) left)</p>
        <button style={classes.chessBtn} onClick={props.onClickAccept}>Accept</button>
        <button style={classes.chessBtn} onClick={props.onClickReject}>No</button>
      </div>
    </div>
  )
}