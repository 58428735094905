export function reset() {
  return {
    type: '@@Chess/reset' as const
  }
}

export function play(rowID: number, colID: number) {
  return {
    type: '@@Chess/play' as const,
    rowID,
    colID
  }
}

export function setSettingMode(){
  return {
    type: '@@Chess/setSettingMode' as const,
  }
}

export function setCheckReminder(boolean: boolean){
  return {
    type: '@@Chess/setCheckReminder' as const,
    boolean
  }
}

export function pawnPromotion(chess: 'queen' | 'bishop' | 'knight' | 'rook'){
  return {
    type: '@@Chess/pawnPromotion' as const,
    chess
  }
}

export function giveUp(){
  return {
    type: '@@Chess/giveUp' as const,
  }
}

export function withdrawal(){
  return {
    type: '@@Chess/withdrawal' as const,
  }
}

export function viewChessHistory(id: number){
  return {
    type: '@@Chess/viewChessHistory' as const,
    id
  }
}

export function deleteChessHistory(id: number){
  return {
    type: '@@Chess/deleteChessHistory' as const,
    id
  }
}

export function showRecordedChessArray(num: number){
  return {
    type: '@@Chess/showRecordedChessArray' as const,
    num
  }
}

export function changeTheme(){
  return {
    type: '@@Chess/changeTheme' as const,
  }
}

export function changeDirection(direction: "up" | "left"){
  return {
    type: '@@Chess/changeDirection' as const,
    direction
  }
}

export function changeHiddenBar(){
  return {
    type: '@@Chess/changeHiddenBar' as const,
  }
}

export function makeDrawRequest(){
  return {
    type: '@@Chess/makeDrawRequest' as const,
  }
}

export function replyDrawRequest(){
  return {
    type: '@@Chess/replyDrawRequest' as const,
  }
}

export function init(){
  return {
    type: '@@Chess/init' as const,
  }
}

export type IChessAction =
  | ReturnType<typeof reset>
  | ReturnType<typeof play>
  | ReturnType<typeof setSettingMode>
  | ReturnType<typeof setCheckReminder>
  | ReturnType<typeof pawnPromotion>
  | ReturnType<typeof giveUp>
  | ReturnType<typeof withdrawal>
  | ReturnType<typeof viewChessHistory>
  | ReturnType<typeof deleteChessHistory>
  | ReturnType<typeof showRecordedChessArray>
  | ReturnType<typeof changeTheme>
  | ReturnType<typeof changeDirection>
  | ReturnType<typeof changeHiddenBar>
  | ReturnType<typeof makeDrawRequest>
  | ReturnType<typeof replyDrawRequest>
  | ReturnType<typeof init>
