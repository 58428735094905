import { push } from "connected-react-router"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { ILoginForm } from "../../redux/Login/state"
import { registrationThunk } from "../../redux/Login/thunk"
import { IRootState } from "../../redux/store"


export default function Registration(){
  
  const [passwordConfirm, setPasswordConfirm] = useState(true)
  const loginStatus = useSelector((state: IRootState)=> state.Login.loginStatus)
  const { register, handleSubmit, formState: { errors } } = useForm();

  const dispatch = useDispatch()

  useEffect(()=>{
    if (loginStatus){
      dispatch(push('/'))
    }
  }, [loginStatus])

  // function clickRegistration(){
  //   let registrationForm: any = document.querySelector('#registrationForm')
  //   if (registrationForm.password.value !== registrationForm.confirm.value){
  //     alert('Those passwords didn’t match. Try again.')
  //   } else {
  //     dispatch(registrationThunk(registrationForm.username.value, registrationForm.password.value))
  //   }
  // }

  function updateConfirm(){
    setPasswordConfirm(true)
  }

  const clickRegistration = (data: {username: string, password: string, confirm: string}) => {
    if (data.password !== data.confirm){
      setPasswordConfirm(false)
    } else {
      dispatch(registrationThunk(data.username, data.password))
    }
  };

  return (
    <div>
      <h1>Create your account</h1>
      <form id="registrationForm" onSubmit={handleSubmit(clickRegistration)}>
        <input placeholder="Username" {...register('username', { required: true, maxLength: 25 })}></input>
        <br/>
        {errors.username && errors.username.type === "required" && <span>This is required</span>}
        {errors.username && errors.username.type === "maxLength" && <span>Max length exceeded</span> }
        <br/>
        <input placeholder="Password" {...register('password', { required: true, maxLength: 25 })}></input>
        <br/>
        {errors.password && errors.password.type === "required" && <span>This is required</span>}
        {errors.password && errors.password.type === "maxLength" && <span>Max length exceeded</span> }
        <br/>
        <input placeholder="Confirm" {...register('confirm', { required: true, maxLength: 25 })} onChange={updateConfirm}></input>
        <br/>
        {errors.confirm && errors.confirm.type === "required" && <span>This is required</span>}
        {errors.confirm && errors.confirm.type === "maxLength" && <span>Max length exceeded</span>}
        {passwordConfirm === false ? <span>Those passwords didn’t match. Try again.</span> : ""}
        <br/>
        <input type="Submit"></input>
      </form>
    </div>
  )
}