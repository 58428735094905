import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store";
import Board from "../Board/Board";
import Setting from "../Tools/Setting";
import SideBar from "../Tools/SideBar";


export default function ConnectFour(){

  const [gameStart, setGameStart] = useState(false)
  const [settingMode, setSettingMode] = useState(false)
  const [lines, setLines] = useState(7)
  const [term, setTerm] = useState(1)
  const [currentPlayer, setCurrentPlayer] = useState('Yellow')
  const [status, setStatus] = useState("playing")
  const [colorArray, setColorArray] = useState([
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
  ])
  const [winArray, setWinArray] = useState<any>([])
  const [lastStep, setLastStep] = useState<any>({row: null, col: null})
  const [viewMode, setViewMode] = useState(false)
  const [drawMode, setDrawMode] = useState(0)
  const [winner, setWinner] = useState<string | undefined>(undefined)
  const [history, setHistory] = useState<{
    currentPlayer: "Black" | "White", 
    row: number, 
    col: number,
    chessArray: {
      name: string, 
      color: string
    }[][]
  }[]>([])
  const direction = useSelector((state: IRootState) => state.Chess.direction)
  const hiddenBar = useSelector((state: IRootState) => state.Chess.hiddenBar)


  const clickSound = new Audio('http://freesoundeffect.net/sites/default/files/game-piece-slide-1-sound-effect-99743653.mp3')

  function clickPlay(rowID: number, colID: number){
    if (rowID !== 0){
      return
    } 
    if (viewMode === true){
      return
    }
    for (let i = 6; i > 0; i--){
      if(colorArray[i][colID] === ""){
        clickSound.play()
        let newArray = colorArray.slice()
        newArray[i][colID] = currentPlayer
        setColorArray(newArray)
        let newObject = lastStep
        newObject.row = i
        newObject.col = colID
        setLastStep(newObject)
        let result = checkWinForConnectFour(newArray)
        if (result !== true){
          if (currentPlayer === "Yellow"){
            setCurrentPlayer("Red")
          } else {
            setCurrentPlayer("Yellow")
          }
          setTerm(term + 1)
        }
        return
      }
    }
  }

  function checkWinForConnectFour(array: Array<Array<string>>){
    let counter = 42;
    let newArray = []
    for (let i = 1; i < 7; i++) {
      for (let j = 0; j < 7; j++) {
        if (array[i][j] !== ""){
          counter = counter - 1;
          if (j < 4 &&
          array[i][j] === array[i][j + 1] && 
          array[i][j] === array[i][j + 2] && 
          array[i][j] === array[i][j + 3]
          ) {
            console.log('horizon: ', i, ', ', j)
            newArray.push({row: i, col: j})
            newArray.push({row: i, col: j + 1})
            newArray.push({row: i, col: j + 2})
            newArray.push({row: i, col: j + 3})
            setStatus("win")
            setViewMode(true)
            setWinArray(newArray)
            return true
          }
          if (i < 4 &&
          array[i][j] === array[i + 1][j] && 
          array[i][j] === array[i + 2][j] && 
          array[i][j] === array[i + 3][j]) {
            console.log('vertical: ', i, ', ', j)
            newArray.push({row: i, col: j})
            newArray.push({row: i + 1, col: j})
            newArray.push({row: i + 2, col: j})
            newArray.push({row: i + 3, col: j})
            setStatus("win")
            setViewMode(true)
            setWinArray(newArray)
            return true
          }
          if (i < 4 && j < 4 &&
          array[i][j] === array[i + 1][j + 1] && 
          array[i][j] === array[i + 2][j + 2] && 
          array[i][j] === array[i + 3][j + 3]) {
            console.log('lateral: ', i, ', ', j)
            newArray.push({row: i, col: j})
            newArray.push({row: i + 1, col: j + 1})
            newArray.push({row: i + 2, col: j + 2})
            newArray.push({row: i + 3, col: j + 3})
            newArray.push({row: i + 4, col: j + 4})
            setStatus("win")
            setViewMode(true)
            setWinArray(newArray)
            return true
          }
          if (j > 3 && i < 4 && 
          array[i][j] === array[i + 1][j - 1] && 
          array[i][j] === array[i + 2][j - 2] && 
          array[i][j] === array[i + 3][j - 3]) {
            console.log('reverse-lateral: ', i, ', ', j)
            newArray.push({row: i, col: j})
            newArray.push({row: i + 1, col: j - 1})
            newArray.push({row: i + 2, col: j - 2})
            newArray.push({row: i + 3, col: j - 3})
            newArray.push({row: i + 4, col: j - 4})
            setStatus("win")
            setViewMode(true)
            setWinArray(newArray)
            return true
          }
        }
      }
    }
    if (counter === 0){
      setStatus("draw")
      setViewMode(true)
      setTimeout(()=>{
        alert("Draw!")
      }, 100)
    }
  }

  useEffect(()=>{
    console.log('status changed')
    if (status === "win"){
      setTimeout(()=>{
        alert(`${currentPlayer} win!`)
      }, 100)
    } else if (status === "draw"){
      setTimeout(()=>{
        alert(`Draw!`)
      }, 100)
    }
  }, [status])

  function clickReset(){
    setGameStart(true)
    setSettingMode(false)
    setDrawMode(0)
    setHistory([])
    setTerm(1)
    setCurrentPlayer("Yellow")
    setStatus("playing")
    setColorArray([
      ["", "", "", "", "", "", ""],
      ["", "", "", "", "", "", ""],
      ["", "", "", "", "", "", ""],
      ["", "", "", "", "", "", ""],
      ["", "", "", "", "", "", ""],
      ["", "", "", "", "", "", ""],
      ["", "", "", "", "", "", ""],
    ])
    setWinArray(new Array)
    setLastStep({row: null, col: null})
    setViewMode(false)
  }

  return (
    <div style={{position: 'relative', width: "100%", height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <SideBar 
        gameStart={gameStart}
        term={term}
        currentPlayer={currentPlayer}
        viewMode={viewMode}
        history={history}
        direction={direction}
        hiddenBar={hiddenBar}
        winner={winner}
        line={7}
        onClickBack={()=>{}}
        onClickNext={()=>{}}
        onClickSetting={()=>setSettingMode(true)}
      />
      <div>
        <Board 
          onSquareClick={clickPlay}
          lines={lines} 
          winArray={winArray}
          colorArray={colorArray} 
          lastStep={lastStep}
          gameMode={"ConnectFour"}
        />
      </div>
      {gameStart === false || settingMode
      ?<Setting
        title="ConnectFour"
        viewMode={viewMode}
        gameStart={gameStart}
        drawMode={drawMode}
        currentPlayer={currentPlayer}
        term={term}
        winner={winner}
        onClickSetting={()=>setSettingMode(false)}
        onClickReset={clickReset}
        onClickMakeRequest={()=>setDrawMode(drawMode + 1)}
        onClickGiveUp={()=>{}}
        onClickWithdrawal={()=>{}}
      />
      :null
      }
    </div>
  )
}