import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../redux/store';
import { useEffect, useState } from 'react';
import Board from '../Board/Board'
import {play, result, reset, save, pass, makingReversiHistoryList} from '../../redux/Reversi/action'
import { push } from 'connected-react-router';
import SideBar from '../Tools/SideBar';
import { wrongSound } from '../../Utility/type';
import Setting from '../Tools/Setting';

export default function Reversi(){

  const [gameStart, setGameStart] = useState(false)
  const [settingMode, setSettingMode] = useState(false)
  const currentPlayer = useSelector((state: IRootState) => state.Reversi.currentPlayer)
  const term = useSelector((state: IRootState) => state.Reversi.term)
  const ReversiWinner = useSelector((state: IRootState) => state.Reversi.ReversiWinner)
  const ReversiBlack = useSelector((state: IRootState) => state.Reversi.ReversiBlack)
  const ReversiWhite = useSelector((state: IRootState) => state.Reversi.ReversiWhite)
  const viewMode = useSelector((state: IRootState) => state.Reversi.viewMode)
  const historyViewMode = useSelector((state: IRootState) => state.Reversi.historyViewMode)
  const lastStep = useSelector((state: IRootState) => state.Reversi.lastStep)
  const colorArray = useSelector((state: IRootState) => state.Reversi.colorArray)
  const [drawMode, setDrawMode] = useState(0)
  const [winner, setWinner] = useState<string | undefined>(undefined)
  const [history, setHistory] = useState<{
    currentPlayer: "Black" | "White", 
    row: number, 
    col: number,
    chessArray: {
      name: string, 
      color: string
    }[][]
  }[]>([])
  const direction = useSelector((state: IRootState) => state.Chess.direction)
  const hiddenBar = useSelector((state: IRootState) => state.Chess.hiddenBar)

  const dispatch = useDispatch()

  function clickPlay(rowID: number, colID: number) {
    if (ReversiWinner !== "") {
      return
    }
    if (colorArray[rowID][colID] !== "") {
      console.log('wrong click')
      wrongSound.play()
      return
    }
    console.log('dispatch play')
    dispatch(play(rowID, colID))
  }

  function clickResult(blackNum: number, whiteNum: number){
    dispatch(result(blackNum, whiteNum))
  }

  useEffect(()=>{
    if (viewMode === true){
      return
    }
    console.log("ReversiBlack: ", ReversiBlack)
    console.log("ReversiWhite: ", ReversiWhite)
    if (ReversiWinner === "Black"){
      setTimeout(()=>{
      alert(`
Black: ${ReversiBlack}; 
White: ${ReversiWhite}. 
Black Win!`)
      }, 100)
      dispatch(save())
    } else if (ReversiWinner === "White"){
      setTimeout(()=>{
      alert(`
Black: ${ReversiBlack}; 
White: ${ReversiWhite}. 
White Win!`)
      }, 100)
      dispatch(save())
    } else if (ReversiWinner === "Nobody. Draw!"){
      setTimeout(()=>{
      alert(`
Black: ${ReversiBlack}; 
White: ${ReversiBlack}. 
Draw!`)
      }, 100)
      dispatch(save())
    }
  }, [ReversiWinner])

  function clickReset() {
    setGameStart(true)
    setSettingMode(false)
    setDrawMode(0)
    setHistory([])
    dispatch(reset())
  }

  function clickPass(){
    setSettingMode(false)
    dispatch(pass())
  }

  useEffect(()=>{
    dispatch(makingReversiHistoryList())
  }, [])

  return (
    <div style={{position: 'relative', width: "100%", height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <SideBar 
        gameStart={gameStart}
        term={term}
        currentPlayer={currentPlayer}
        viewMode={viewMode}
        history={history}
        direction={direction}
        hiddenBar={hiddenBar}
        winner={winner}
        blackNum={ReversiBlack}
        whiteNum={ReversiWhite}
        onClickBack={()=>{}}
        onClickNext={()=>{}}
        onClickSetting={()=>setSettingMode(true)}
        line={8}
      />
      <div>
        <Board 
          onSquareClick={viewMode === false ? clickPlay : ()=>{}} 
          lines={8} 
          winArray={[{row: null, col: null}]}
          colorArray={colorArray} 
          lastStep={lastStep}
          gameMode={"Reversi"}
        />
      </div>
      {gameStart === false || settingMode
      ?<Setting
        title="Reversi"
        viewMode={viewMode}
        gameStart={gameStart}
        drawMode={drawMode}
        currentPlayer={currentPlayer}
        term={term}
        winner={winner}
        onClickSetting={()=>setSettingMode(false)}
        onClickReset={clickReset}
        onClickMakeRequest={()=>setDrawMode(drawMode + 1)}
        onClickGiveUp={()=>{}}
        onClickWithdrawal={()=>{}}
        onClickPass={clickPass}
        onClickResult={()=>clickResult(ReversiBlack, ReversiWhite)}
      />
      :null
      }
    </div>
  )
}