import {IReversiState, initialState} from './state'
import {IReversiAction} from './action' 


function checkWinForReversi(state: IReversiState, rowID: number, colID: number):{state: IReversiState, status: boolean}{

  const clickSound = new Audio('http://freesoundeffect.net/sites/default/files/game-piece-slide-1-sound-effect-99743653.mp3')
  const wrongSound = new Audio('https://www.sfxbuzz.com/jdownloads/Buzzer,%20Alarm%20and%20Siren%20Sound%20Effects/Wrong%20Clakson%20Sound%20Effect.mp3')

  let currentPlayer = state.currentPlayer
  let colorArray = [...state.colorArray]
  // colorArray[rowID] = [...colorArray[rowID]]
  colorArray[rowID][colID] = currentPlayer
  state = {...state, colorArray}
  // up check
  let upCounter = 0;
  let counterCheck = 0;
  
  for (let i = 1; i < (rowID + 1); i++){
    if (colorArray[rowID - i][colID] === currentPlayer || colorArray[rowID - i][colID] === ""){
      break
    } else {
      upCounter = upCounter + 1
    }
  }
  if ((rowID - upCounter - 1) >= 0 && currentPlayer === colorArray[rowID - upCounter - 1][colID]){
    for (let i = 1; i < (upCounter + 1); i ++){
      counterCheck = counterCheck + 1
      colorArray[rowID - i][colID] = currentPlayer
    }
  }
  // down check
  let downCounter = 0;
  for (let i = 1; i < (8 - rowID); i++){
    if (colorArray[rowID + i][colID] === currentPlayer || colorArray[rowID + i][colID] === ""){
      break
    } else {
      downCounter = downCounter + 1
    }
  }
  if ((rowID + downCounter + 1) <= (8 - 1) && currentPlayer === colorArray[rowID + downCounter + 1][colID]){
    for (let i = 1; i < (downCounter + 1); i ++){
      counterCheck = counterCheck + 1
      colorArray[rowID + i][colID] = currentPlayer
    }
  }
  // left check
  let leftCounter = 0;
  for (let i = 1; i < (colID + 1); i++){
    if (colorArray[rowID][colID - i] === currentPlayer || colorArray[rowID][colID - i] === ""){
      break
    } else {
      leftCounter = leftCounter + 1
    }
  }
  if ((colID - leftCounter - 1) >= 0 && currentPlayer === colorArray[rowID][colID - leftCounter - 1]){
    for (let i = 1; i < (leftCounter + 1); i ++){
      counterCheck = counterCheck + 1
      colorArray[rowID][colID - i] = currentPlayer
    }
  }
  // right check
  let rightCounter = 0;
  for (let i = 1; i < (8 - colID); i++){
    if (colorArray[rowID][colID + i] === currentPlayer || colorArray[rowID][colID + i] === ""){
      break
    } else {
      rightCounter = rightCounter + 1
    }
  }
  if ((colID + rightCounter + 1) <= (8 - 1) && currentPlayer === colorArray[rowID][colID + rightCounter + 1]){
    for (let i = 1; i < (rightCounter + 1); i ++){
      counterCheck = counterCheck + 1
      colorArray[rowID][colID + i] = currentPlayer
    }
  }
  // left up check
  let leftUpCounter = 0;
  let leftUpVariable;
  if (rowID > colID){
    leftUpVariable = colID
  } else {
    leftUpVariable = rowID
  }
  for (let i = 1; i < (leftUpVariable + 1); i++){
    if (colorArray[rowID - i][colID - i] === currentPlayer || colorArray[rowID - i][colID - i] === ""){
      break
    } else {
      leftUpCounter = leftUpCounter + 1
    }
  }
  if ((leftUpVariable - leftUpCounter - 1) >= 0 && currentPlayer === colorArray[rowID - leftUpCounter - 1][colID - leftUpCounter - 1]){
    for (let i = 1; i < (leftUpCounter + 1); i ++){
      counterCheck = counterCheck + 1
      colorArray[rowID - i][colID - i] = currentPlayer
    }
  }
  // right down check
  let rightDownCounter = 0;
  let rightDownVariable;
  if ((8 - rowID) > (8 - colID)){
    rightDownVariable = 8 - colID
  } else {
    rightDownVariable = 8 - rowID
  }
  for (let i = 1; i < (rightDownVariable); i++){
    if (colorArray[rowID + i][colID + i] === currentPlayer || colorArray[rowID + i][colID + i] === ""){
      break
    } else {
      rightDownCounter = rightDownCounter + 1
    }
  }
  if ((rightDownVariable - rightDownCounter - 1) > 0 && (rightDownVariable + rightDownCounter + 1) <= 18 && currentPlayer === colorArray[rowID + rightDownCounter + 1][colID + rightDownCounter + 1]){
    for (let i = 1; i < (rightDownCounter + 1); i ++){
      counterCheck = counterCheck + 1
      colorArray[rowID + i][colID + i] = currentPlayer
    }
  }
  // left down check
  let leftDownCounter = 0;
  let leftDownVariable;
  if ((8 - rowID) > (colID)){
    leftDownVariable = colID + 1
  } else {
    leftDownVariable = 8 - rowID
  }
  for (let i = 1; i < (leftDownVariable); i++){
    if (colorArray[rowID + i][colID - i] === currentPlayer || colorArray[rowID + i][colID - i] === ""){
      break
    } else {
      leftDownCounter = leftDownCounter + 1
    }
  }
  if ((leftDownVariable - leftDownCounter - 1) > 0 && (leftDownVariable - leftDownCounter - 1) < (8 - 1) && currentPlayer === colorArray[rowID + leftDownCounter + 1][colID - leftDownCounter - 1]){
    for (let i = 1; i < (leftDownCounter + 1); i ++){
      counterCheck = counterCheck + 1
      colorArray[rowID + i][colID - i] = currentPlayer
    }
  }
  // right up check
  let rightUpCounter = 0;
  let rightUpVariable;
  if ((rowID) > (8 - colID)){
    rightUpVariable = 8 - colID
  } else {
    rightUpVariable = rowID + 1
  }
  for (let i = 1; i < (rightUpVariable); i++){
    if (colorArray[rowID - i][colID + i] === currentPlayer || colorArray[rowID - i][colID + i] === ""){
      break
    } else {
      rightUpCounter = rightUpCounter + 1
    }
  }
  if ((rightUpVariable - rightUpCounter - 1) > 0 && (rightUpVariable - rightUpCounter - 1) < (8 - 1) && currentPlayer === colorArray[rowID - rightUpCounter - 1][colID + rightUpCounter + 1]){
    for (let i = 1; i < (rightUpCounter + 1); i ++){
      counterCheck = counterCheck + 1
      colorArray[rowID - i][colID + i] = currentPlayer
    }
  }
  let counter = 64
  let whiteNum = 0;
  let blackNum = 0;
  for (let i = 0; i < (8); i++) {
    for (let j = 0; j < (8); j++) {
      if (colorArray[i][j] === "Black"){
        blackNum = blackNum + 1
      }
      if (colorArray[i][j] === "White"){
        whiteNum = whiteNum + 1
      }
      if (colorArray[i][j] !== ""){
        counter = counter - 1
      }
    }
  }
  if (counterCheck === 0){
    colorArray[rowID][colID] = "";
    wrongSound.play()
    state = {...state, colorArray}
    return {state: state, status: true}
  } else {
    clickSound.play()
    let newLastStep = {row: rowID, col: colID}
    state = {...state, lastStep: newLastStep, ReversiBlack: blackNum, ReversiWhite: whiteNum}
  }
  if (blackNum === 0){
    state = result(state, blackNum, whiteNum)
    return {state: state, status: true}
  }
  if (whiteNum === 0){
    state = result(state, blackNum, whiteNum)
    return {state: state, status: true}
  }
  if (counter === 0){
    state = result(state, blackNum, whiteNum)
    return {state: state, status: true}
  }
  return {state: state, status: false}
}

function result(state: IReversiState, blackNum: number, whiteNum: number): IReversiState{
  if (blackNum > whiteNum){
    state = {...state, ReversiWinner: "Black"}
  } else if (blackNum < whiteNum){
    state = {...state, ReversiWinner: "White"}
  } else {
    state = {...state, ReversiWinner: "Nobody. Draw!"}
  }
  return state
}

function init(){
  let reversiHistory = localStorage.getItem('Reversi History')
  let reversiHistoryInJS = [];
  if (reversiHistory !== null){
    reversiHistoryInJS = JSON.parse(reversiHistory)
  }
  return reversiHistoryInJS
}

export const ReversiReducer = (
  state: IReversiState = initialState,
  action: IReversiAction,
): IReversiState => {

  switch (action.type) {
    case '@@Reversi/play': {
      let result = checkWinForReversi(state, action.rowID, action.colID)
      state = result.state
      if (result.status !== true){
        state = {
          ...state,
          currentPlayer: state.currentPlayer === "Black" ? "White" : "Black" ,
          term: state.term + 1
        }
      }
      return state
    }
    case '@@Reversi/result': {
      if (action.blackNum > action.whiteNum){
        state = {...state, ReversiWinner: "Black"}
      } else if (action.blackNum < action.whiteNum){
        state = {...state, ReversiWinner: "White"}
      } else {
        state = {...state, ReversiWinner: "Nobody. Draw!"}
      }
      return state
    }
    case '@@Reversi/save': {
      let newArray = state.savedReversiResult
      let currentTime = new Date()
      newArray.push({chessArray: state.colorArray, time: (currentTime.toLocaleTimeString() + " " + currentTime.toLocaleDateString()), black: state.ReversiBlack, white: state.ReversiWhite, term: state.term, winner: state.ReversiWinner})
      state = { 
        ...state, 
        savedReversiResult: newArray,
        historyViewMode: true,
        viewMode: true
      }
      localStorage.setItem('Reversi History', JSON.stringify(newArray))
      return state
    }
    case '@@Reversi/make-history-list': {
      let result = init()
      state = {...state, savedReversiResult: result}
      return state
    }
    case '@@Reversi/pass': {
      let value = state.term + 1
      let player = state.currentPlayer 
      if (state.currentPlayer === "Black"){
        player = "White"
      } else {
        player = "Black"
      }
      state = {...state, term: value, currentPlayer: player}
      return state
    }
    case '@@Reversi/view-history': {
      state = {
        ...state, 
        colorArray: state.savedReversiResult[action.id - 1].chessArray,
        viewMode: true,
        ReversiBlack: state.savedReversiResult[action.id - 1].black,
        ReversiWhite: state.savedReversiResult[action.id - 1].white,
        term: state.savedReversiResult[action.id - 1].term,
        ReversiWinner: state.savedReversiResult[action.id - 1].winner
      }
      return state
    }
    case '@@Reversi/delete-history': {
      let newArray = [];
      for (let i = 0; i < state.savedReversiResult.length; i++){
        if (i !== (action.id - 1)){
          newArray.push(state.savedReversiResult[i])
        }
      }
      localStorage.setItem('Reversi History', JSON.stringify(newArray))
      state = {...state, savedReversiResult: newArray}
      return state
    }
    case '@@Reversi/reset': {
      init()
      return {...initialState,
        colorArray: [
          ["", "", "", "", "", "", "", ""],
          ["", "", "", "", "", "", "", ""],
          ["", "", "", "", "", "", "", ""],
          ["", "", "", "White", "Black", "", "", ""],
          ["", "", "", "Black", "White", "", "", ""],
          ["", "", "", "", "", "", "", ""],
          ["", "", "", "", "", "", "", ""],
          ["", "", "", "", "", "", "", ""]
        ],
        savedReversiResult: state.savedReversiResult
      }
      // return {...initialState,
      //   colorArray: [...initialState.colorArray],
      //   savedReversiResult: result
      // }
    }
    default: {
      return state
    }
  }
}