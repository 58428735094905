import { useSelector } from "react-redux"
import { IRootState } from "../../redux/store"

export function Todo(){

  const user = useSelector((state: IRootState)=>state.Login.user)

  return (
      <>
        <h1>Welcome</h1>
        <h2>{user?.username}</h2>
        <h3>{user?.user_id}</h3>
        <h3>{user?.role}</h3>
      </>
    )
}