import produce from "immer";
import { clickSound, wrongSound } from "../../Utility/type";
import { ICChessAction } from "./action";
import { ICChessState, initialState } from "./state";

function prediction(state: ICChessState, rowID: number, colID:number){
  let chessArray = state.chessArray;
  let currentPlayer = state.currentPlayer;
  let noStepCounter = 0;
  if (state.chessArray[rowID][colID].name === "兵"){
    if (rowID >= 5){
      if (chessArray[rowID - 1][colID].color === currentPlayer){wrongSound.play(); return}
      chessArray[rowID - 1][colID].color = "green"
      noStepCounter = noStepCounter + 1
    } else {
      if (rowID - 1 >= 0 && chessArray[rowID - 1][colID].color !== currentPlayer){
        chessArray[rowID - 1][colID].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID + 1 <= 8 && chessArray[rowID][colID + 1].color !== currentPlayer){
        chessArray[rowID][colID + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID - 1 >= 0 && chessArray[rowID][colID - 1].color !== currentPlayer){
        chessArray[rowID][colID - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
  } 
  if (chessArray[rowID][colID].name === "卒"){
    if (rowID < 5){
      if (chessArray[rowID + 1][colID].color === currentPlayer){wrongSound.play(); return}
      chessArray[rowID + 1][colID].color = "green"
      noStepCounter = noStepCounter + 1
    } else {
      if (rowID + 1 < 10 && chessArray[rowID + 1][colID].color !== currentPlayer){
        chessArray[rowID + 1][colID].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID + 1 < 9 && chessArray[rowID][colID + 1].color !== currentPlayer){
        chessArray[rowID][colID + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID - 1 >= 0 && chessArray[rowID][colID - 1].color !== currentPlayer){
        chessArray[rowID][colID - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
  }
  if (chessArray[rowID][colID].name === "炮" || chessArray[rowID][colID].name === "砲"){
    let upCounter = 0
    let downCounter = 0
    let leftCounter = 0
    let rightCounter = 0
    for (let i = 0; i < (9 - rowID); i++){
      if (chessArray[rowID + i + 1][colID].color === ""){
        if (downCounter === 0){
          chessArray[rowID + i + 1][colID].color = "green"
          noStepCounter = noStepCounter + 1
        }
      } else {
        downCounter = downCounter + 1
        if (downCounter === 2 && chessArray[rowID + i + 1][colID].color !== currentPlayer){
          chessArray[rowID + i + 1][colID].color = "green"
          noStepCounter = noStepCounter + 1
          break
        }
      }
    }
    for (let i = 0; i < rowID; i++){
      if (chessArray[rowID - i - 1][colID].color === ""){
        if (upCounter === 0){
          chessArray[rowID - i - 1][colID].color = "green"
          noStepCounter = noStepCounter + 1
        }
      } else {
        upCounter = upCounter + 1
        if (upCounter === 2 && chessArray[rowID - i - 1][colID].color !== currentPlayer){
          chessArray[rowID - i - 1][colID].color = "green"
          noStepCounter = noStepCounter + 1
          break
        }
      }
    }
    for (let i = 0; i < (8 - colID); i++){
      if (chessArray[rowID][colID + i + 1].color === ""){
        if (rightCounter === 0){
          chessArray[rowID][colID + i + 1].color = "green"
          noStepCounter = noStepCounter + 1
        }
      } else {
        rightCounter = rightCounter + 1
        if (rightCounter === 2 && chessArray[rowID][colID + i + 1].color !== currentPlayer){
          chessArray[rowID][colID + i + 1].color = "green"
          noStepCounter = noStepCounter + 1
          break
        }
      }
    }
    for (let i = 0; i < colID; i++){
      if (chessArray[rowID][colID - i - 1].color === ""){
        if (leftCounter === 0){
          chessArray[rowID][colID - i - 1].color = "green"
          noStepCounter = noStepCounter + 1
        }
      } else {
        leftCounter = leftCounter + 1
        if (leftCounter === 2 && chessArray[rowID][colID - i - 1].color !== currentPlayer){
          chessArray[rowID][colID - i - 1].color = "green"
          noStepCounter = noStepCounter + 1
          break
        }
      }
    }
  }
  if (chessArray[rowID][colID].name === "車" || chessArray[rowID][colID].name === "俥"){
    for (let i = 0; i < (9 - rowID); i++){
      if (chessArray[rowID + i + 1][colID].color === ""){
        chessArray[rowID + i + 1][colID].color = "green"
        noStepCounter = noStepCounter + 1
      } else {
        if (chessArray[rowID + i + 1][colID].color !== currentPlayer){
          chessArray[rowID + i + 1][colID].color = "green"
          noStepCounter = noStepCounter + 1
        }
        break
      }
    }
    for (let i = 0; i < rowID; i++){
      if (chessArray[rowID - i - 1][colID].color === ""){
        chessArray[rowID - i - 1][colID].color = "green"
        noStepCounter = noStepCounter + 1
      } else {
        if (chessArray[rowID - i - 1][colID].color !== currentPlayer){
          chessArray[rowID - i - 1][colID].color = "green"
          noStepCounter = noStepCounter + 1
        }
        break
      }
    }
    for (let i = 0; i < (8 - colID); i++){
      if (chessArray[rowID][colID + i + 1].color === ""){
        chessArray[rowID][colID + i + 1].color = "green"
        noStepCounter = noStepCounter + 1
      } else {
        if (chessArray[rowID][colID + i + 1].color !== currentPlayer){
          chessArray[rowID][colID + i + 1].color = "green"
          noStepCounter = noStepCounter + 1
        }
        break
      }
    }
    for (let i = 0; i < colID; i++){
      if (chessArray[rowID][colID - i - 1].color === ""){
        chessArray[rowID][colID - i - 1].color = "green"
        noStepCounter = noStepCounter + 1
      } else {
        if (chessArray[rowID][colID - i - 1].color !== currentPlayer){
          chessArray[rowID][colID - i - 1].color = "green"
          noStepCounter = noStepCounter + 1
        }
        break
      }
    }
  }
  if (chessArray[rowID][colID].name === "將" || chessArray[rowID][colID].name === "帥"){
    if (rowID + 1 <= 9 && (rowID + 1 <= 2 || rowID + 1 > 7)){
      if (chessArray[rowID + 1][colID].color !== currentPlayer){
        chessArray[rowID + 1][colID].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
    if (rowID - 1 >= 0 && (rowID - 1 <= 2 || rowID - 1 >= 7)){
      if (chessArray[rowID - 1][colID].color !== currentPlayer){
        chessArray[rowID - 1][colID].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
    if (colID + 1 >= 3 && colID + 1 <= 5){
      if (chessArray[rowID][colID + 1].color !== currentPlayer){
        chessArray[rowID][colID + 1].color = "green"
        noStepCounter = noStepCounter + 1
      } 
    }
    if (colID - 1 >= 3 && colID - 1 <= 5){
      if (chessArray[rowID][colID - 1].color !== currentPlayer){
        chessArray[rowID][colID - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
    if (chessArray[rowID][colID].name === "將"){
      for (let i = 0; i < (9 - rowID); i++){
        if (chessArray[rowID + i + 1] [colID].name !== ""){
          if (chessArray[rowID + i + 1][colID].name === "帥"){
            chessArray[rowID + i + 1][colID].color = "green"
            noStepCounter = noStepCounter + 1
          }
          break
        }
      }
    }
    if (chessArray[rowID][colID].name === "帥"){
      for (let i = 0; i < rowID; i++){
        if (chessArray[rowID - i - 1][colID].name !== ""){
          if (chessArray[rowID - i - 1][colID].name === "將"){
            chessArray[rowID - i - 1][colID].color = "green"
            noStepCounter = noStepCounter + 1
          }
          break
        }
      }
    }
  }
  if (chessArray[rowID][colID].name === "仕"){
    if (rowID + 1 <= 9){
      if (colID + 1 <= 5 && chessArray[rowID + 1][colID + 1].color !== currentPlayer){
        chessArray[rowID + 1][colID + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if(colID - 1 >= 3 && chessArray[rowID + 1][colID - 1].color !== currentPlayer){
        chessArray[rowID + 1][colID - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
    if (rowID - 1 >= 7){
      if (colID - 1 >= 3 && chessArray[rowID - 1][colID - 1].color !== currentPlayer){
        chessArray[rowID - 1][colID - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID + 1 <= 5 && chessArray[rowID - 1][colID + 1].color !== currentPlayer){
        chessArray[rowID - 1][colID + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
  }
  if (chessArray[rowID][colID].name === "士"){
    if (rowID + 1 < 3){
      if (colID + 1 <= 5 && chessArray[rowID + 1][colID + 1].color !== currentPlayer){
        chessArray[rowID + 1][colID + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if(colID - 1 >= 3 && chessArray[rowID + 1][colID - 1].color !== currentPlayer){
        chessArray[rowID + 1][colID - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
    if (rowID - 1 >= 0){
      if (colID - 1 >= 3 && chessArray[rowID - 1][colID - 1].color !== currentPlayer){
        chessArray[rowID - 1][colID - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID + 1 <= 5 && chessArray[rowID - 1][colID + 1].color !== currentPlayer){
        chessArray[rowID - 1][colID + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
  }
  if (chessArray[rowID][colID].name === "相"){
    if (rowID - 2 >= 5){
      if (colID + 2 <= 8 && chessArray[rowID - 1][colID + 1].color === "" && chessArray[rowID - 2][colID + 2].color !== currentPlayer){
        chessArray[rowID - 2][colID + 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID - 2 >= 0 && chessArray[rowID - 1][colID - 1].color === "" && chessArray[rowID - 2][colID - 2].color !== currentPlayer){
        chessArray[rowID - 2][colID - 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
    } 
    if (rowID + 2 <= 9){
      if (colID + 2 <= 8 && chessArray[rowID + 1][colID + 1].color === "" && chessArray[rowID + 2][colID + 2].color !== currentPlayer){
        chessArray[rowID + 2][colID + 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID - 2 >= 0 && chessArray[rowID + 1][colID - 1].color === "" && chessArray[rowID + 2][colID - 2].color !== currentPlayer){
        chessArray[rowID + 2][colID - 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
  }
  if (chessArray[rowID][colID].name === "象"){
    if (rowID - 2 >= 0){
      if (colID + 2 <= 8 && chessArray[rowID - 1][colID + 1].color === "" && chessArray[rowID - 2][colID + 2].color !== currentPlayer){
        chessArray[rowID - 2][colID + 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID - 2 >= 0 && chessArray[rowID - 1][colID - 1].color === "" && chessArray[rowID - 2][colID - 2].color !== currentPlayer){
        chessArray[rowID - 2][colID - 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
    } 
    if (rowID + 2 < 5){
      if (colID + 2 <= 8 && chessArray[rowID + 1][colID + 1].color === "" && chessArray[rowID + 2][colID + 2].color !== currentPlayer){
        chessArray[rowID + 2][colID + 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID - 2 >= 0 && chessArray[rowID + 1][colID - 1].color === "" && chessArray[rowID + 2][colID - 2].color !== currentPlayer){
        chessArray[rowID + 2][colID - 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
  }
  if (chessArray[rowID][colID].name === "馬" || chessArray[rowID][colID].name === "傌"){
    if (rowID - 2 >= 0 && chessArray[rowID - 1][colID].color === ""){
      if (colID + 1 <= 8 && chessArray[rowID - 2][colID + 1].color !== currentPlayer){
        chessArray[rowID - 2][colID + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID - 1 >= 0 && chessArray[rowID - 2][colID - 1].color !== currentPlayer){
        chessArray[rowID - 2][colID - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
    if (rowID + 2 <= 9 && chessArray[rowID + 1][colID].color === ""){
      if (colID + 1 <= 8 && chessArray[rowID + 2][colID + 1].color !== currentPlayer){
        chessArray[rowID + 2][colID + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID - 1 >= 0 && chessArray[rowID + 2][colID - 1].color !== currentPlayer){
        chessArray[rowID + 2][colID - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
    if (colID - 2 >= 0 && chessArray[rowID][colID - 1].color === ""){
      if (rowID + 1 <= 9 && chessArray[rowID + 1][colID - 2].color !== currentPlayer){
        chessArray[rowID + 1][colID - 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (rowID - 1 >= 0 && chessArray[rowID - 1][colID - 2].color !== currentPlayer){
        chessArray[rowID - 1][colID - 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
    if (colID + 2 <= 8 && chessArray[rowID][colID + 1].color === ""){
      if (rowID + 1 <= 9 && chessArray[rowID + 1][colID + 2].color !== currentPlayer){
        chessArray[rowID + 1][colID + 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (rowID - 1 >= 0 && chessArray[rowID - 1][colID + 2].color !== currentPlayer){
        chessArray[rowID - 1][colID + 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
  }
  if (noStepCounter === 0){
    wrongSound.play()
    return
  }
  state.currentChess = {
    ...state.currentChess, 
    name: chessArray[rowID][colID].name,
    row: rowID,
    col: colID
  }
  state.clickedMode = true
}

function play(state: ICChessState, rowID: number, colID:number){
  if (state.viewMode === true){return}
  if (state.clickedMode === false){
    if (state.chessArray[rowID][colID].color === state.currentPlayer){
      prediction(state, rowID, colID)
    } else if (state.chessArray[rowID][colID].color !== ""){
      wrongSound.play()
      return
    }
  } else {
    let redCounter = 90
    let blackCounter = 90
    if (state.chessArray[rowID][colID].color === "green"){
      if (state.currentChess.name === "帥"){
        state.redKingPosition.row = rowID
        state.redKingPosition.col = colID
      }
      if (state.currentChess.name === "將"){
        state.blackKingPosition.row = rowID
        state.blackKingPosition.col = colID
      }
      state.chessArray[rowID][colID].color = state.currentPlayer
      state.chessArray[rowID][colID].name = state.currentChess.name
      state.chessArray[state.currentChess.row][state.currentChess.col].color = ""
      state.chessArray[state.currentChess.row][state.currentChess.col].name = ""
      state.lastChess = {row: rowID, col: colID}
      let result = clearMode(state, redCounter, blackCounter, rowID, colID)
      clickSound.play()
      state.history.push({
        currentPlayer: state.currentPlayer, 
        row: rowID, 
        col: colID, 
        chessArray: [...state.chessArray]
      })
      if (result.isEnd !== true){
        if (state.currentPlayer === 'red'){
          state.currentPlayer = 'black'
        } else {
          state.currentPlayer = 'red'
        } 
        state.term = state.term + 1
      } else {
        state.winner = state.currentPlayer
        state.savedChessResult.push({
          history: state.history,
          time: ((new Date()).toLocaleTimeString() + " " + (new Date()).toLocaleDateString()),
          winner: state.currentPlayer
        })
        localStorage.setItem('Chinese Chess History', JSON.stringify(state.savedChessResult))
      } 
    } else if (state.chessArray[rowID][colID].color === state.currentPlayer && (state.currentChess.col !== colID || state.currentChess.row !== rowID)){
      clearMode(state, redCounter, blackCounter, rowID, colID)
      prediction(state, rowID, colID)
    } else {
      clearMode(state, redCounter, blackCounter, rowID, colID)
    }
  }
}

function clearMode(state: ICChessState, redCounter: number, blackCounter: number, rowID: number, colID: number){
  for (let i = 0; i < 10; i++){
    for (let j = 0; j < 9; j++){
      if (state.chessArray[i][j].name !== '將'){
        blackCounter = blackCounter - 1
      }
      if (state.chessArray[i][j].name !== '帥'){
        redCounter = redCounter - 1
      }
      if (state.chessArray[i][j].color === "green"){
        if (state.chessArray[i][j].name === ""){
          state.chessArray[i][j].color = ""
        } else {
          if (state.currentPlayer === "red"){
            state.chessArray[i][j].color = "black"
          } else {
            state.chessArray[i][j].color = "red"
          }
        }
      }
    }
  }
  if (redCounter === 0){
    state.viewMode = true
    state.settingMode = true
    return {isEnd: true}
  }
  if (blackCounter === 0){
    state.viewMode = true
    state.settingMode = true
    return {isEnd: true}
  }
  state.clickedMode = false
  state.currentChess = {name:"", row: rowID, col: colID}
  return {isEnd: false}
}

function giveUp(state: ICChessState){
  let currentChess = state.currentChess
  if (state.term === 1){
    alert(`You have not started the game!`)
    return
  }
  if (state.currentPlayer === "black"){
    state.winner = "red"
  } else {
    state.winner = "black"
  }
  state.currentPlayer = state.winner
  clearMode(state, 90, 90, currentChess.row, currentChess.col)
  state.viewMode = true
  state.savedChessResult.push({
    history: state.history,
    time: ((new Date()).toLocaleTimeString() + " " + (new Date()).toLocaleDateString()),
    winner: state.winner
  })
  localStorage.setItem('Chess History', JSON.stringify(state.savedChessResult))
}

function withdrawal(state: ICChessState){
  let history = state.history
  if (state.term < 3){return}
  state.chessArray = history[history.length - 2].chessArray
  state.term = state.term - 1
  state.lastChess = {row: history[history.length - 2].row, col: history[history.length - 2].col}
  state.settingMode = false
  state.currentPlayer === "red" ? state.currentPlayer = "black" : state.currentPlayer = "red"
  history.pop()
}

export const CChessReducer = produce((
  state: ICChessState = initialState,
  action: ICChessAction,
): void => {
  switch (action.type){
    case '@@CChess/play':
      play(state, action.rowID, action.colID)
      return 

    case '@@CChess/setSettingMode':
      if (state.settingMode === true){
        state.settingMode = false
      } else {
        state.settingMode = true
      }
      return 
    
    case '@@CChess/setCheckReminder':
      state.checkReminder = action.boolean
      return 

    case '@@CChess/giveUp':
      giveUp(state)
      return 

    case '@@CChess/withdrawal':
      withdrawal(state)
      return 
    
    case '@@CChess/deleteChessHistory': 
      let newArray = state.savedChessResult
      newArray.splice(action.id - 1, 1)
      state.savedChessResult = newArray
      localStorage.setItem('Chess History', JSON.stringify(state.savedChessResult))
      return

    case '@@CChess/viewChessHistory': 
      state.history = state.savedChessResult[action.id - 1].history
      state.currentPlayer = state.savedChessResult[action.id - 1].history[state.savedChessResult[action.id - 1].history.length -1].currentPlayer
      state.chessArray = state.savedChessResult[action.id - 1].history[state.savedChessResult[action.id - 1].history.length -1].chessArray
      state.lastChess = {row: state.savedChessResult[action.id - 1].history[state.savedChessResult[action.id - 1].history.length -1].row, col: state.savedChessResult[action.id - 1].history[state.savedChessResult[action.id - 1].history.length -1].col}
      state.viewMode = true
      state.gameStart = true
      state.settingMode = false
      state.term = state.savedChessResult[action.id - 1].history.length
      state.winner = state.savedChessResult[action.id - 1].winner
      return

    case '@@CChess/showRecordedChessArray':
      state.currentPlayer = state.history[action.num - 1].currentPlayer
      state.chessArray = state.history[action.num - 1].chessArray
      state.lastChess = {row: state.history[action.num - 1].row, col: state.history[action.num - 1].col}
      state.term = action.num
      return 

    case '@@CChess/init':
      let chessHistory = localStorage.getItem('Chinese Chess History')
      let chessHistoryInJS = [];
      if (chessHistory !== null){
        chessHistoryInJS = JSON.parse(chessHistory)
      }
      state.savedChessResult = chessHistoryInJS
      return 

    case '@@CChess/makeDrawRequest':
      state.drawMode = state.drawMode + 1
      return 

    case '@@CChess/replyDrawRequest':
      state.drawMode = 0
      state.viewMode = true
      state.winner = "draw"
      state.savedChessResult.push({
        history: state.history,
        time: ((new Date()).toLocaleTimeString() + " " + (new Date()).toLocaleDateString()),
        winner: "draw"
      })
      localStorage.setItem('Chinese Chess History', JSON.stringify(state.savedChessResult))
      return 

    case '@@CChess/reset':
      state.gameStart = true
      state.settingMode = false
      state.term = 1
      state.clickedMode = false
      state.currentPlayer = "red"
      state.currentChess = {name:"", row: 0, col: 0}
      state.lastChess = {row: 0, col: 0}
      state.redKingPosition = {row: 9, col: 4}
      state.blackKingPosition = {row: 0, col: 4}
      state.viewMode = false
      state.checkReminder = false
      state.chessArray = [
        [{name: "車", color: "black"}, {name: "馬", color: "black"}, {name: "象", color: "black"}, {name: "士", color: "black"}, {name: "將", color: "black"}, {name: "士", color: "black"}, {name: "象", color: "black"}, {name: "馬", color: "black"}, {name: "車", color: "black"}],
        [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}],
        [{name: "", color: ""}, {name: "砲", color: "black"}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "砲", color: "black"}, {name: "", color: ""}],
        [{name: "卒", color: "black"}, {name: "", color: ""}, {name: "卒", color: "black"}, {name: "", color: ""}, {name: "卒", color: "black"}, {name: "", color: ""}, {name: "卒", color: "black"}, {name: "", color: ""}, {name: "卒", color: "black"}],
        [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}],
        [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}],
        [{name: "兵", color: "red"}, {name: "", color: ""}, {name: "兵", color: "red"}, {name: "", color: ""}, {name: "兵", color: "red"}, {name: "", color: ""}, {name: "兵", color: "red"}, {name: "", color: ""}, {name: "兵", color: "red"}],
        [{name: "", color: ""}, {name: "炮", color: "red"}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "炮", color: "red"}, {name: "", color: ""}],
        [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}],
        [{name: "俥", color: "red"}, {name: "傌", color: "red"}, {name: "相", color: "red"}, {name: "仕", color: "red"}, {name: "帥", color: "red"}, {name: "仕", color: "red"}, {name: "相", color: "red"}, {name: "傌", color: "red"}, {name: "俥", color: "red"}],
      ]
      state.history = []
      state.drawMode = 0
      delete state.winner
      return 
  }
}, initialState)