import produce from 'immer';
import { ILoginAction } from './action';
import { ILoginState, initialState, JWTPayload } from './state';
import jwt_decode from 'jwt-decode'

export const LoginReducer = produce((
  state: ILoginState = initialState,
  action: ILoginAction,
): void =>{

  switch (action.type){
    case '@@Login/load_token':{
      try {
        let payload: JWTPayload = jwt_decode(action.token)
        state.loginStatus = true
        state.user = payload
        state.error = undefined
        return
      } catch (error) {
        state.error='invalid JWT Token'
        state.user=undefined
        return
      }
    }

    case '@@Login/login':
      state.loginStatus = true
      // state.isAuthenticated = true
      state.msg = ""
      return
    case '@@Login/registration':
      state.loginStatus = true
      // state.isAuthenticated = true
      state.msg = ""
      return
    case '@@Login/logout':
      state.loginStatus = false
      state.user=undefined
      return
  }
}, initialState)