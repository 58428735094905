import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../redux/store';
import Board from '../Board/Board'
import {play, updateLine, reset, save, makingFiveChessHistoryList} from '../../redux/FiveChess/action'
import { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import './FiveChess.css';
import SideBar from '../Tools/SideBar';
import Setting from '../Tools/Setting';
import { clickSound, wrongSound } from '../../Utility/type';

export default function FiveChess(){

  const [gameStart, setGameStart] = useState(false)
  const [settingMode, setSettingMode] = useState(false)
  const lines = useSelector((state: IRootState) => state.FiveChess.lines)
  const currentPlayer = useSelector((state: IRootState) => state.FiveChess.currentPlayer)
  const term = useSelector((state: IRootState) => state.FiveChess.term)
  const status = useSelector((state: IRootState) => state.FiveChess.status)
  const winArray = useSelector((state: IRootState) => state.FiveChess.winArray)
  const lastStep = useSelector((state: IRootState) => state.FiveChess.lastStep)
  const colorArray = useSelector((state: IRootState) => state.FiveChess.colorArray)
  const viewMode = useSelector((state: IRootState)=> state.FiveChess.viewMode)
  const [drawMode, setDrawMode] = useState(0)
  const [winner, setWinner] = useState<string | undefined>(undefined)
  const [history, setHistory] = useState<{
    currentPlayer: "Black" | "White", 
    row: number, 
    col: number,
    chessArray: {
      name: string, 
      color: string
    }[][]
  }[]>([])
  const direction = useSelector((state: IRootState) => state.Chess.direction)
  const hiddenBar = useSelector((state: IRootState) => state.Chess.hiddenBar)

  const dispatch = useDispatch()

  function clickPlay(rowID: number, colID: number) {
    if (status === "playing" && viewMode === false){
      if (colorArray[rowID][colID] !== "") {
        wrongSound.play()
      } else {
        clickSound.play()
      }
    }
    dispatch(play(rowID, colID))
  }

  function lineUpdate(e: any) {
    let value = parseInt(e.target.value)
    if (value >= 9 && value <= 19){
      dispatch(updateLine(value))
    }
  }

  useEffect(()=>{
    if (viewMode === true){
      return
    }
    if (status === "win"){
      alert(`${currentPlayer} win!`)
      dispatch(save())
    }
    if (status === "draw"){
      alert(`Draw!`)
      dispatch(save())
    }
  }, [status])

  function clickViewHistoryList(){
    dispatch(push('/HistoryList'));
  }

  useEffect(()=>{
    dispatch(makingFiveChessHistoryList())
  }, [])

  function clickReset(){
    setGameStart(true)
    setSettingMode(false)
    setDrawMode(0)
    setHistory([])
    dispatch(reset())
  }

  // function clickGiveUp(){
  //   if (currentPlayer === "red"){
  //     setCurrentPlayer("black")
  //     setWinner("black")
  //   } else {
  //     setCurrentPlayer("red")
  //     setWinner("red")
  //   }
  //   setViewMode(true)
  // }

  return (
    <div style={{position: 'relative', width: "100%", height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <SideBar 
        gameStart={gameStart}
        term={term}
        currentPlayer={currentPlayer}
        viewMode={viewMode}
        history={history}
        direction={direction}
        hiddenBar={hiddenBar}
        winner={winner}
        line={lines}
        onClickLineUpdate={lineUpdate}
        onClickBack={()=>{}}
        onClickNext={()=>{}}
        onClickSetting={()=>setSettingMode(true)}
      />
      <div>
        <Board 
          onSquareClick={clickPlay}
          lines={lines} 
          winArray={winArray}
          colorArray={colorArray} 
          lastStep={lastStep}
          gameMode={"FiveChess"}
        />
      </div>
      {gameStart === false || settingMode
      ?<Setting
        title="FiveChess"
        viewMode={viewMode}
        gameStart={gameStart}
        drawMode={drawMode}
        currentPlayer={currentPlayer}
        term={term}
        winner={winner}
        onClickSetting={()=>setSettingMode(false)}
        onClickReset={clickReset}
        onClickMakeRequest={()=>setDrawMode(drawMode + 1)}
        onClickGiveUp={()=>{}}
        onClickWithdrawal={()=>{}}
      />
      :null
      }
    </div>
  )
}