import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTheme, giveUp, makeDrawRequest, pawnPromotion, play, replyDrawRequest, reset, setCheckReminder, setSettingMode, showRecordedChessArray, withdrawal } from "../../redux/Chess/action";
import { IRootState } from "../../redux/store";
import './Chess.css';
import SideBar from "../Tools/SideBar";
import { chessImg, chessImg2 } from "../../Utility/type";
import Setting from "../Tools/Setting";
import CheckReminder from "../Tools/CheckReminder";
import { classes } from "../../Utility/class";
import GiveUp from "../Tools/GiveUp";


export default function Chess(){

  const gameStart = useSelector((state: IRootState)=> state.Chess.gameStart)
  const settingMode = useSelector((state: IRootState)=> state.Chess.settingMode)
  const term = useSelector((state: IRootState)=> state.Chess.term)
  const currentPlayer = useSelector((state: IRootState)=> state.Chess.currentPlayer)
  const lastChess = useSelector((state: IRootState)=> state.Chess.lastChess)
  const whiteKingPosition = useSelector((state: IRootState)=> state.Chess.whiteKingPosition)
  const blackKingPosition = useSelector((state: IRootState)=> state.Chess.blackKingPosition)
  const viewMode = useSelector((state: IRootState)=> state.Chess.viewMode)
  const promotionMode = useSelector((state: IRootState)=> state.Chess.promotionMode)
  const checkReminder = useSelector((state: IRootState)=> state.Chess.checkReminder)
  const chessArray = useSelector((state: IRootState)=> state.Chess.chessArray)
  const history = useSelector((state: IRootState) => state.Chess.history)
  const chessTheme = useSelector((state: IRootState) => state.Chess.chessTheme)
  const direction = useSelector((state: IRootState) => state.Chess.direction)
  const hiddenBar = useSelector((state: IRootState) => state.Chess.hiddenBar)
  const drawMode = useSelector((state: IRootState) => state.Chess.drawMode)
  const winner = useSelector((state: IRootState) => state.Chess.winner)
  const [coordinate, setCoordinate] = useState<{row: null | number, col: null | number}>({row: null, col: null})

  const dispatch = useDispatch()

  useEffect(()=>{
    if (currentPlayer === "black"){
      checkCheck(currentPlayer, blackKingPosition.row, blackKingPosition.col)
    } else {
      checkCheck(currentPlayer, whiteKingPosition.row, whiteKingPosition.col)
    }
  }, [dispatch, term])

  function checkCheck(player: "black" | "white", row: number, col: number){
    if (viewMode === true || term === 1){return}
    let rival = "b"
    if (player === "black"){
      rival = "w"
    }
    let checkCounter = 0;
    for (let i = 0; i < (7 - row); i++){
      if (i === 0 && chessArray[row + i + 1][col].name === rival + "king"){
        checkCounter = checkCounter + 1
      }
      if (chessArray[row + i + 1][col].name === rival + "rook" || chessArray[row + i + 1][col].name === rival + "queen"){
        checkCounter = checkCounter + 1
      }
      if (chessArray[row + i + 1][col].color !== ""){
        break
      }
    }
    for (let i = 0; i < row; i++){
      if (i === 0 && chessArray[row - i - 1][col].name === rival + "king"){
        checkCounter = checkCounter + 1
      }
      if (chessArray[row - i - 1][col].name === rival + "rook" || chessArray[row - i - 1][col].name === rival + "queen"){
        checkCounter = checkCounter + 1
      }
      if (chessArray[row - i - 1][col].color !== ""){
        break
      }
    }
    for (let i = 0; i < (7 - col); i++){
      if (i === 0 && chessArray[row][col + i + 1].name === rival + "king"){
        checkCounter = checkCounter + 1
      }
      if (chessArray[row][col + i + 1].name === rival + "rook" || chessArray[row][col + i + 1].name === rival + "queen"){
        checkCounter = checkCounter + 1
      }
      if (chessArray[row][col + i + 1].color !== ""){
        break
      }
    }
    for (let i = 0; i < col; i++){
      if (i === 0 && chessArray[row][col - i - 1].name === rival + "king"){
        checkCounter = checkCounter + 1
      }
      if (chessArray[row][col - i - 1].name === rival + "rook" || chessArray[row][col - i - 1].name === rival + "queen"){
        checkCounter = checkCounter + 1
      }
      if (chessArray[row][col - i - 1].color !== ""){
        break
      }
    }
    let upLeftNum = row;
    if (col < row){
      upLeftNum = col 
    }
    for (let i = 0; i < upLeftNum; i++){
      if (i === 0 && (chessArray[row - i - 1][col - i - 1].name === rival + "king" || (chessArray[row - i - 1][col - i - 1].name === "bpawn" && player === "white"))){
        checkCounter = checkCounter + 1
      }
      if (chessArray[row - i - 1][col - i - 1].name === rival + "bishop" || chessArray[row - i - 1][col - i - 1].name === rival + "queen"){
        checkCounter = checkCounter + 1
      }
      if (chessArray[row - i - 1][col - i - 1].color !== ""){
        break
      }
    }
    let upRightNum = row
    if ((7 - col) < row){
      upRightNum = 7 - col
    }
    for (let i = 0; i < upRightNum; i++){
      if (i === 0 && (chessArray[row - i - 1][col + i + 1].name === rival + "king" || (chessArray[row - i - 1][col + i + 1].name === "bpawn" && player === "white"))){
        checkCounter = checkCounter + 1
      }
      if (chessArray[row - i - 1][col + i + 1].name === rival + "bishop" || chessArray[row - i - 1][col + i + 1].name === rival + "queen"){
        checkCounter = checkCounter + 1
      } 
      if (chessArray[row - i - 1][col + i + 1].color !== ""){
        break
      }
    }
    let downRightNum = row
    if (col > row){
      downRightNum = col 
    }
    for (let i = 0; i < (7 - downRightNum); i++){
      if (i === 0 && (chessArray[row + i + 1][col + i + 1].name === rival + "king" || (chessArray[row + i + 1][col + i + 1].name === "wpawn" && player === "black"))){
        checkCounter = checkCounter + 1
      }
      if (chessArray[row + i + 1][col + i + 1].name === rival + "bishop" || chessArray[row + i + 1][col + i + 1].name === rival + "queen"){
        checkCounter = checkCounter + 1
      }
      if (chessArray[row + i + 1][col + i + 1].color !== ""){
        break
      }
    }
    let downLeftNum = 7 - row
    if (col < 7 - row){
      downLeftNum = col
    }
    for (let i = 0; i < downLeftNum; i++){
      if (i === 0 && (chessArray[row + i + 1][col - i - 1].name === rival + "king" || (chessArray[row + i + 1][col - i - 1].name === "wpawn" && player === "black"))){
        checkCounter = checkCounter + 1
      }
      if (chessArray[row + i + 1][col - i - 1].name === rival + "bishop" || chessArray[row + i + 1][col - i - 1].name === rival + "queen"){
        checkCounter = checkCounter + 1
      }
      if (chessArray[row + i + 1][col - i - 1].color !== ""){
        break
      }
    }
    if (row - 2 >= 0){
      if (col + 1 <= 7 && chessArray[row - 2][col + 1].name === rival + "knight"){
        checkCounter = checkCounter + 1
      }
      if (col - 1 >= 0 && chessArray[row - 2][col - 1].name === rival + "knight"){
        checkCounter = checkCounter + 1
      }
    }
    if (row + 2 <= 7){
      if (col + 1 <= 7 && chessArray[row + 2][col + 1].name === rival + "knight"){
        checkCounter = checkCounter + 1
      }
      if (col - 1 >= 0 && chessArray[row + 2][col - 1].name === rival + "knight"){
        checkCounter = checkCounter + 1
      }
    }
    if (col - 2 >= 0){
      if (row + 1 <= 7 && chessArray[row + 1][col - 2].name === rival + "knight"){
        checkCounter = checkCounter + 1
      }
      if (row - 1 >= 0 && chessArray[row - 1][col - 2].name === rival + "knight"){
        checkCounter = checkCounter + 1
      }
    }
    if (col + 2 <= 7){
      if (row + 1 <= 7 && chessArray[row + 1][col + 2].name === rival + "knight"){
        checkCounter = checkCounter + 1
      }
      if (row - 1 >= 0 && chessArray[row - 1][col + 2].name === rival + "knight"){
        checkCounter = checkCounter + 1
      }
    }
    console.log("checkCheck rival: ", rival)
    if (checkCounter > 0){
      console.log("dispatch")
      dispatch(setCheckReminder(true))
    }
  }

  function clickPlay(rowID: number, colID: number){
    dispatch(play(rowID, colID))
  }

  function buildChessDom(){
    let Squares = [];
    for (let i = 0; i < 8; i++){
      let rows = []
      for (let j = 0; j < 8; j++){
        let img = chessImg[chessArray[i][j].name]
        if (chessTheme === false){
          img = chessImg2[chessArray[i][j].name]
        }
        rows.push(
          <button 
            key={"row"+i+"column"+j} 
            style={{
              width: "10vmin", height: "10vmin", 
              background: i%2 === 0 && j%2 === 1 || i%2 === 1 && j%2 === 0
              ? "#ffce9e"
              : "#d18b47",
              padding: chessTheme ?5 :0,
            }} 
            onClick={()=>{
              setCoordinate({row: null, col: null})
              clickPlay(i, j)
            }}
            onDragStart={()=>{
              setCoordinate({row: null, col: null})
              clickPlay(i, j)
            }}
            onDragOver={()=>setCoordinate({row: i, col: j})}
            onDragEnd={()=>coordinate.row !== null && coordinate.col !== null && clickPlay(coordinate.row, coordinate.col)}
          >
            <div style={{
              color: chessArray[i][j].color,
              borderRadius: "50%",
              width: "100%", 
              height: "100%",
              backgroundImage: chessArray[i][j].color === "green"
                ? "radial-gradient(rgba(154, 205, 50, 1), rgba(154, 205, 50, 1), rgba(154, 205, 50, 0), rgba(154, 205, 50, 0))" 
                : "radial-gradient(rgba(154, 205, 50, 0), rgba(154, 205, 50, 0), rgba(154, 205, 50, 0))",
              outline: chessArray[i][j].color === "green" && chessArray[i][j].name !== ""
              ? "dashed rgba(255, 0, 0, 0.5) 5px"
              : i === lastChess.row && j === lastChess.col && term > 1
              ? "dashed rgba(0, 128, 0, 0.5) 5px"
              : "none",
              outlineOffset: chessTheme ?"0px" :"-5px",
              }}
            >
              {img === null
                ?null
                :<img style={{width: "100%", height: "100%"}} src={img} />
              }
            </div>
          </button>
        )
      }
      Squares.push(
        <div key={"row"+i} style={{display: "flex", justifyContent: "center"}}>
          {rows}
        </div>
      )
    }

    return Squares
  }

  function clickReset(){
    dispatch(reset())
  }

  function clickPawnPromotion(chess: 'queen' | 'bishop' | 'knight' | 'rook'){
    dispatch(pawnPromotion(chess))
  }

  function clickGiveUp(){
    dispatch(giveUp())
  }

  function clickWithdrawal(){
    dispatch(withdrawal())
  }

  function clickSetting(){
    dispatch(setSettingMode())
  }

  function clickBack(){
    if (term <= 1 ){return}
    dispatch(showRecordedChessArray(term - 1))
  }

  function clickNext(){
    if (term + 1 > history.length){return}
    dispatch(showRecordedChessArray(term + 1))
  }

  return (
    <div style={{position: 'relative', width: "100%", height: "100%", display: 'flex', alignItems: 'center'}}>
      <SideBar 
        gameStart={gameStart} 
        term={term} 
        currentPlayer={currentPlayer} 
        viewMode={viewMode} 
        history={history} 
        chessTheme={chessTheme} 
        direction={direction} 
        hiddenBar={hiddenBar} 
        winner={winner} 
        onClickBack={clickBack}
        onClickNext={clickNext}
        onClickSetting={()=>dispatch(setSettingMode())}
      />
      <div style={{width: "100%"}}>
        {buildChessDom()}
      </div>
      {promotionMode === true
      ?<div style={{position: 'absolute', top: 0, height: '100%', width: '100%', background: 'rgba(50, 50, 50, 0.8', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <h1 style={{color: 'white'}}>Pawn Promotion</h1>
          <button style={classes.chessBtn} onClick={()=>clickPawnPromotion('queen')}>Queen</button>
          <button style={classes.chessBtn} onClick={()=>clickPawnPromotion('bishop')}>Bishop</button>
          <button style={classes.chessBtn} onClick={()=>clickPawnPromotion('knight')}>Knight</button>
          <button style={{padding: "5px 30px", borderRadius: 10, fontSize: 20, backgroundColor: "white", color: "black"}} onClick={()=>clickPawnPromotion('rook')}>Rook</button>
        </div>
      </div>
      :null
      }
      {checkReminder === true
      ?<CheckReminder
        currentPlayer={currentPlayer}
        onClickOK={()=>dispatch(setCheckReminder(false))}
      />
      :null
      }
      {gameStart === false || settingMode
      ?<Setting
        title="Chess"
        viewMode={viewMode}
        gameStart={gameStart}
        drawMode={drawMode}
        currentPlayer={currentPlayer}
        term={term}
        winner={winner}
        onClickSetting={clickSetting}
        onClickReset={clickReset}
        onClickChangeTheme={()=>dispatch(changeTheme())}
        onClickMakeRequest={()=>dispatch(makeDrawRequest())}
        onClickGiveUp={clickGiveUp}
        onClickWithdrawal={clickWithdrawal}
      />
      :null
      }
      {drawMode%2 === 1 && drawMode < 6
      ?<GiveUp
        currentPlayer={currentPlayer}
        drawMode={drawMode}
        onClickAccept={()=>dispatch(replyDrawRequest())}
        onClickReject={()=>dispatch(makeDrawRequest())}
      />
      :null
      }
    </div>
  )
}

