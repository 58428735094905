import {IFiveChessState, initialState} from './state'
import {IFiveChessAction} from './action'
import produce from 'immer';

function checkWinForFiveChess(state: IFiveChessState): void{
  let counter = (state.lines*state.lines)
  let array = state.colorArray
  let newArray = []
  for (let i = 0; i < (state.lines); i++) {
    for (let j = 0; j < (state.lines); j++) {
      if (array[i][j] !== "") {
        counter = counter - 1;
        if (j < 15 && 
        array[i][j] === array[i][j + 1] && 
        array[i][j] === array[i][j + 2] && 
        array[i][j] === array[i][j + 3] && 
        array[i][j] === array[i][j + 4]) {
          newArray.push({row: i, col: j})
          newArray.push({row: i, col: j + 1})
          newArray.push({row: i, col: j + 2})
          newArray.push({row: i, col: j + 3})
          newArray.push({row: i, col: j + 4})
          state.status = "win"
          state.winArray = newArray
          return
        }
        if (i < 15 && array[i][j] === array[i + 1][j] && array[i][j] === array[i + 2][j] && array[i][j] === array[i + 3][j] && array[i][j] === array[i + 4][j]) {
          newArray.push({row: i, col: j})
          newArray.push({row: i + 1, col: j})
          newArray.push({row: i + 2, col: j})
          newArray.push({row: i + 3, col: j})
          newArray.push({row: i + 4, col: j})
          state.status = "win"
          state.winArray = newArray
          return
        }
        if (i < 15 && j < 15 && array[i][j] === array[i + 1][j + 1] && array[i][j] === array[i + 2][j + 2] && array[i][j] === array[i + 3][j + 3] && array[i][j] === array[i + 4][j + 4]) {
          newArray.push({row: i, col: j})
          newArray.push({row: i + 1, col: j + 1})
          newArray.push({row: i + 2, col: j + 2})
          newArray.push({row: i + 3, col: j + 3})
          newArray.push({row: i + 4, col: j + 4})
          state.status = "win"
          state.winArray = newArray
          return
        }
        if (j > 3 && i < 15 && array[i][j] === array[i + 1][j - 1] && array[i][j] === array[i + 2][j - 2] && array[i][j] === array[i + 3][j - 3] && array[i][j] === array[i + 4][j - 4]) {
          newArray.push({row: i, col: j})
          newArray.push({row: i + 1, col: j - 1})
          newArray.push({row: i + 2, col: j - 2})
          newArray.push({row: i + 3, col: j - 3})
          newArray.push({row: i + 4, col: j - 4})
          state.status = "win"
          state.winArray = newArray
          return
        }
      }
    }
  }
  if (counter === 0){
    state.status = "draw"
    return
  }
}

function init(){
  let reversiHistory = localStorage.getItem('Five Chess History')
  let reversiHistoryInJS = [];
  if (reversiHistory !== null){
    reversiHistoryInJS = JSON.parse(reversiHistory)
  }
  return reversiHistoryInJS
}

export const FiveChessReducer = produce((
  state: IFiveChessState = initialState,
  action: IFiveChessAction,
): void => {

  switch (action.type) {
    case '@@FiveChess/play': 
      if (state.status !== "playing" || state.viewMode === true) {
        return
      }
      if (state.colorArray[action.rowID][action.colID] !== ""){
        return
      }
      // let colorArray = [...state.colorArray]
      // colorArray[action.rowID] = [...colorArray[action.rowID]]
      // colorArray[action.rowID][action.colID] = state.currentPlayer
      state.lastStep.row = action.rowID
      state.lastStep.col = action.colID
      state.colorArray[action.rowID][action.colID] = state.currentPlayer
      checkWinForFiveChess(state)
      if (state.status === "playing"){
        let value = state.term + 1
        state.term = value
        state.currentPlayer = state.currentPlayer === "Black" ? "White" : "Black"
      }
      return
    
    case '@@FiveChess/save': 
      let newArray = state.savedFiveChessResult
      let currentTime = new Date()
      newArray.push({chessArray: state.colorArray, time: (currentTime.toLocaleTimeString() + " " + currentTime.toLocaleDateString()), term: state.term, winner: state.currentPlayer, lines: state.lines, winArray: state.winArray, lastStep: state.lastStep})
      state.savedFiveChessResult = newArray
      state.viewMode = true
      localStorage.setItem('Five Chess History', JSON.stringify(newArray))
      return
    
    case '@@FiveChess/make-history-list': 
      let result = init()
      state.savedFiveChessResult = result
      return
    
    case '@@FiveChess/view-history': 
      let newArray3 = state.savedFiveChessResult[action.id - 1].chessArray
      Object.assign(state.colorArray, newArray3)
      state.viewMode = true
      state.term = state.savedFiveChessResult[action.id - 1].term
      state.currentPlayer = state.savedFiveChessResult[action.id - 1].winner
      state.lines = state.savedFiveChessResult[action.id - 1].lines
      state.winArray = state.savedFiveChessResult[action.id - 1].winArray
      state.lastStep = state.savedFiveChessResult[action.id - 1].lastStep
      return
    
    case '@@FiveChess/delete-history': 
      let newArray2 = [];
      for (let i = 0; i < state.savedFiveChessResult.length; i++){
        if (i !== (action.id - 1)){
          newArray2.push(state.savedFiveChessResult[i])
        }
      }
      localStorage.setItem('Five Chess History', JSON.stringify(newArray2))
      state.savedFiveChessResult = newArray2
      return
    
    case '@@FiveChess/update-line': 
      state.lines = action.lines
      return
    
    case '@@FiveChess/reset': 
      let newObject = {...initialState, savedFiveChessResult: state.savedFiveChessResult}
      Object.assign(state, newObject)
      return
    
  }
},initialState)
