export type IChessState = {
  status: "ready",
  gameStart: boolean,
  settingMode: boolean,
  term: number,
  clickedMode: boolean,
  currentPlayer: "white" | "black",
  currentChess: {name: string, row: number, col:number},
  lastChess: {row: number, col:number},
  whiteKingPosition: {row: number, col:number},
  blackKingPosition: {row: number, col:number},
  viewMode: boolean,
  promotionMode: boolean,
  checkReminder: boolean,
  chessArray: {name: string, color: string}[][],
  leftWhiteRookMove: number,
  rightWhiteRookMove: number,
  leftBlackRookMove: number,
  rightBlackRookMove: number,
  whiteKingMove: number,
  blackKingMove: number,
  history: {
    currentPlayer: "black" | "white", 
    row: number, 
    col: number,
    leftWhiteRookMove: number,
    rightWhiteRookMove: number,
    leftBlackRookMove: number,
    rightBlackRookMove: number,
    whiteKingMove: number,
    blackKingMove: number,
    chessArray: {
      name: string, 
      color: string
    }[][]
  }[],
  savedChessResult: {
    history: {
      currentPlayer: "black" | "white", 
      row: number, 
      col: number, 
      leftWhiteRookMove: number,
      rightWhiteRookMove: number,
      leftBlackRookMove: number,
      rightBlackRookMove: number,
      whiteKingMove: number,
      blackKingMove: number,
      chessArray: {
        name: string, 
        color: string
      }[][]
    }[],
    time: string,
    winner: "black" | "white" | "draw",
  }[],
  chessTheme: boolean,
  direction: "up" | "left",
  hiddenBar: boolean,
  drawMode: number,
  winner?: "black" | "white" | "draw"
}

export const initialState: IChessState = {
  status: "ready",
  gameStart: false,
  settingMode: false,
  term: 1,
  clickedMode: false,
  currentPlayer: "white",
  currentChess: {name: "", row: 0, col:0},
  lastChess: {row: 0, col: 0},
  whiteKingPosition: {row: 7, col: 4},
  blackKingPosition: {row: 0, col: 4},
  viewMode: false,
  promotionMode: false,
  checkReminder: false,
  chessArray: [
    [{name: "brook", color: "black"}, {name: "bknight", color: "black"}, {name: "bbishop", color: "black"},{name: "bqueen", color: "black"},{name: "bking", color: "black"},{name: "bbishop", color: "black"},{name: "bknight", color: "black"},{name: "brook", color: "black"}],
    [{name: "bpawn", color: "black"}, {name: "bpawn", color: "black"}, {name: "bpawn", color: "black"},{name: "bpawn", color: "black"},{name: "bpawn", color: "black"},{name: "bpawn", color: "black"},{name: "bpawn", color: "black"},{name: "bpawn", color: "black"}],
    [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""}],
    [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""}],
    [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""}],
    [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""}],
    [{name: "wpawn", color: "white"}, {name: "wpawn", color: "white"}, {name: "wpawn", color: "white"},{name: "wpawn", color: "white"},{name: "wpawn", color: "white"},{name: "wpawn", color: "white"},{name: "wpawn", color: "white"},{name: "wpawn", color: "white"}],
    [{name: "wrook", color: "white"}, {name: "wknight", color: "white"}, {name: "wbishop", color: "white"},{name: "wqueen", color: "white"},{name: "wking", color: "white"},{name: "wbishop", color: "white"},{name: "wknight", color: "white"},{name: "wrook", color: "white"}],
  ],
  leftWhiteRookMove: 0,
  rightWhiteRookMove: 0,
  leftBlackRookMove: 0,
  rightBlackRookMove: 0,
  whiteKingMove: 0,
  blackKingMove: 0,
  history: [],
  savedChessResult: [],
  chessTheme: true,
  direction: "left",
  hiddenBar: false,
  drawMode: 0,
}