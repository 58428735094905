import { classes } from "../../Utility/class";


export default function CheckReminder(
  props: {
    currentPlayer: string,
    onClickOK: ()=>void
  }
) {

  const currentPlayer = props.currentPlayer

  return (
    <div style={{position: 'absolute', top: 0, height: '100%', width: '100%', background: 'rgba(50, 50, 50, 0.8', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <h1 style={{color: 'white'}}>{currentPlayer} is checked!</h1>
        <button style={classes.chessBtn} onClick={props.onClickOK}>OK</button>
      </div>
    </div>
  )
}