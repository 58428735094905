import Square from '../Square/Square';

type Props = {
  onSquareClick: (rowID: number, colID:number) => void;
  lines: number;
  winArray: Array<{row: number|null, col: number|null}>
  colorArray: Array<Array<string>>
  lastStep: {row: number|null, col: number|null}
  gameMode: string
}

export default function makeSquares(props: Props) {
  let Squares = [];
  for (let i = 0; i < props.lines; i++) {
    let rows = []
    for (let j = 0; j < props.lines; j++) {
      let color;
      if (props.gameMode === "ConnectFour" && i !== 0){
        color = "Blue"
      } else {
        color = "bisque"
      }
      let winDegree = "";
      for (let x = 0; x < props.winArray.length; x++){
        if (props.winArray[x].row === i && props.winArray[x].col === j) {
          color = "yellow"
        }
      }
      rows.push(<Square rowID={i} colID={j} onSquareClick={props.onSquareClick} chessColor={props.colorArray[i][j]} color={color} winDegree={winDegree} borderColor={(props.lastStep.row === i && props.lastStep.col === j) ? "red" : "grey"} lines={props.lines}/>)
    }
    Squares.push(<div>{rows}</div>)
  }
  return (<>{Squares}</>);
}