import './History.css';

type Props = {
  onViewClick: (id: number) => void;
  onDelClick: (id: number) => void;
  historyID: number,
  time: string,
  winner: string,
  black: number|null,
  white: number|null,
  term?: number,
}

export default function History(props: Props){

  let winner = props.winner.slice(0, 1).toUpperCase() + props.winner.slice(1)

  function view(){
    props.onViewClick(props.historyID)
  }

  function del(){
    props.onDelClick(props.historyID)
  }

  return (
    <div className="reversiHistoryDiv">
      <div className="reversiHistoryInnerDiv">
        <div className="reversiContentText">
          {(winner !== "Black" && winner !== "White" && winner !== "Red" && winner !== "Yellow") ? <span>Draw!</span> : <span>Winner: {winner}</span>}
          {props.black !== null ? <span>Black: {props.black}</span> : ""}
          {props.white !== null ? <span>White: {props.white}</span> : ""}
          {props.term ? <span>Term: {props.term}</span> :null}
        </div>
        <p>Time: {props.time}</p>
      </div>
      <div className="reversiHistoryBtn">
        <button onClick={()=>view()}>View</button>
        <button onClick={()=>del()}>Delete</button>
      </div>
    </div>
  )
}