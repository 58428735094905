

export default function Setting(
  props: {
    title: string,
    viewMode: boolean,
    gameStart: boolean,
    drawMode: number,
    currentPlayer: string,
    term: number,
    winner: string | undefined,
    onClickSetting: ()=>void,
    onClickReset: ()=>void,
    onClickChangeTheme?: ()=>void,
    onClickMakeRequest: ()=>void,
    onClickGiveUp: ()=>void,
    onClickWithdrawal: ()=>void,
    onClickPass?: ()=>void,
    onClickResult?: ()=>void,
  }
){

  const viewMode = props.viewMode
  const gameStart = props.gameStart
  const drawMode = props.drawMode
  const currentPlayer = props.currentPlayer
  const term = props.term
  const winner = props.winner

  return (
    <div style={{position: 'absolute', top: 0, height: '100%', width: '100%', background: 'rgba(50, 50, 50, 0.8', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        {viewMode
          ?<h1 style={{color: 'white'}}>{winner === "draw" ?"Draw!" :currentPlayer + " win!"} </h1>
          :null
        }
        {gameStart 
          ?<button style={classes.chessBtn} onClick={props.onClickSetting}>
            {viewMode ?"View" :"Continue"}
          </button> 
        :null}
        <button style={classes.chessBtn} onClick={props.onClickReset}>New Game</button>
        {props.title === "Chess" &&
          <button style={classes.chessBtn} onClick={props.onClickChangeTheme}>Change Chess Theme</button>
        }
        {props.title === "Reversi" &&
        <>
          <button style={classes.chessBtn} onClick={props.onClickPass}>Pass</button>
          <button style={classes.chessBtn} onClick={props.onClickResult}>Result</button>
        </>}
        {term >= 2 && viewMode === false?<button style={classes.chessBtn} onClick={props.onClickGiveUp}>Give up</button>:null}
        {term >= 10 && drawMode < 6 && viewMode === false?<button style={classes.chessBtn} onClick={props.onClickMakeRequest}>Draw request</button>:null}
        {term >= 3 && viewMode === false?<button style={classes.chessBtn} onClick={props.onClickWithdrawal}>Withdrawal</button> :null}
      </div>
    </div>
  )
}

const classes = {
  chessBtn: {
    marginBottom: 20, 
    padding: "5px 30px", 
    borderRadius: 10, 
    fontSize: 20, 
    backgroundColor: "white", 
    color: "black",
    minWidth: 200,
  },
}