import { useDispatch, useSelector } from "react-redux"
import {useForm} from 'react-hook-form';
import { loginThunk } from "../../redux/Login/thunk";
import { useEffect, useState } from "react";
import { push } from 'connected-react-router';
import { IRootState } from "../../redux/store";


export default function Login(){

  const loginStatus = useSelector((state: IRootState)=> state.Login.loginStatus)
  const { register, handleSubmit, formState: { errors } } = useForm();

  const dispatch = useDispatch()

  useEffect(()=>{
    if (loginStatus === true){
      dispatch(push('/'))
    }
  }, [loginStatus])

  const clickLogin = (data: {username: string, password: string}) => dispatch(loginThunk(data.username, data.password));

  return (
    <div>
      <h1>Login</h1>
      <form id="loginForm" onSubmit={handleSubmit(clickLogin)}>
        <input placeholder="Username" {...register('username', { required: true, maxLength: 25 })}></input>
        <br/>
        {errors.username && errors.username.type === "required" && <span>This is required</span>}
        {errors.username && errors.username.type === "maxLength" && <span>Max length exceeded</span> }
        <br/>
        <input placeholder="Password" {...register('password', { required: true, maxLength: 25 })}></input>
        <br/>
        {errors.password && errors.password.type === "required" && <span>This is required</span>}
        {errors.password && errors.password.type === "maxLength" && <span>Max length exceeded</span> }
        <br/>
        <input type="Submit"></input>
        <input type="button" value="Sign up" onClick={()=>{dispatch(push('/Registration'))}}></input>
      </form>
    </div>
    )
}