export type ICChessState = {
  gameStart: boolean,
  settingMode: boolean,
  term: number,
  clickedMode: boolean,
  currentPlayer: "red" | "black",
  currentChess: {name: string, row: number, col:number},
  lastChess: {row: number, col:number},
  redKingPosition: {row: number, col:number},
  blackKingPosition: {row: number, col:number},
  viewMode: boolean,
  checkReminder: boolean,
  chessArray: {name: string, color: string}[][],
  history: {
    currentPlayer: "red" | "black",
    row: number, 
    col: number,
    chessArray: {
      name: string, 
      color: string
    }[][]
  }[],
  savedChessResult: {
    history: {
      currentPlayer: "red" | "black",
      row: number, 
      col: number, 
      chessArray: {
        name: string, 
        color: string
      }[][]
    }[],
    time: string,
    winner: "red" | "black" | "draw",
  }[],
  drawMode: number,
  winner?: "red" | "black" | "draw"
}

export const initialState: ICChessState = {
  gameStart: false,
  settingMode: false,
  term: 1,
  clickedMode: false,
  currentPlayer: "red",
  currentChess: {name: "", row: 0, col:0},
  lastChess: {row: 0, col: 0},
  redKingPosition: {row: 9, col: 4},
  blackKingPosition: {row: 0, col: 4},
  viewMode: false,
  checkReminder: false,
  chessArray: [
    [{name: "車", color: "black"}, {name: "馬", color: "black"}, {name: "象", color: "black"}, {name: "士", color: "black"}, {name: "將", color: "black"}, {name: "士", color: "black"}, {name: "象", color: "black"}, {name: "馬", color: "black"}, {name: "車", color: "black"}],
    [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}],
    [{name: "", color: ""}, {name: "砲", color: "black"}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "砲", color: "black"}, {name: "", color: ""}],
    [{name: "卒", color: "black"}, {name: "", color: ""}, {name: "卒", color: "black"}, {name: "", color: ""}, {name: "卒", color: "black"}, {name: "", color: ""}, {name: "卒", color: "black"}, {name: "", color: ""}, {name: "卒", color: "black"}],
    [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}],
    [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}],
    [{name: "兵", color: "red"}, {name: "", color: ""}, {name: "兵", color: "red"}, {name: "", color: ""}, {name: "兵", color: "red"}, {name: "", color: ""}, {name: "兵", color: "red"}, {name: "", color: ""}, {name: "兵", color: "red"}],
    [{name: "", color: ""}, {name: "炮", color: "red"}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "炮", color: "red"}, {name: "", color: ""}],
    [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""}],
    [{name: "俥", color: "red"}, {name: "傌", color: "red"}, {name: "相", color: "red"}, {name: "仕", color: "red"}, {name: "帥", color: "red"}, {name: "仕", color: "red"}, {name: "相", color: "red"}, {name: "傌", color: "red"}, {name: "俥", color: "red"}],
  ],
  history: [],
  savedChessResult: [],
  drawMode: 0,
}