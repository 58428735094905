export type IReversiState = {
  currentPlayer : "Black" | "White"
  term: number
  ReversiWinner: "" | "Black" | "White" | "Nobody. Draw!"
  ReversiBlack: number
  ReversiWhite: number
  savedReversiResult: Array<any>
  viewMode: boolean
  historyViewMode: boolean
  lastStep: {row: number|null, col: number|null}
  colorArray: Array<Array<string>>
}

export const initialState: IReversiState = {
  currentPlayer :  "Black",
  term: 1,
  ReversiWinner: "",
  ReversiBlack: 2,
  ReversiWhite: 2,
  savedReversiResult: [],
  viewMode: false,
  historyViewMode: false,
  lastStep: {row: null, col: null},
  colorArray: [
    ["", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", ""],
    ["", "", "", "White", "Black", "", "", ""],
    ["", "", "", "Black", "White", "", "", ""],
    ["", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", ""]
  ]
}