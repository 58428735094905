import { IChessAction } from "./action"
import { IChessState, initialState } from "./state"
import produce from 'immer';
import { clickSound, wrongSound } from "../../Utility/type";

function rookRule(state: IChessState, noStepCounter: number, rowID: number, colID: number){
  let chessArray = state.chessArray
  let currentPlayer = state.currentPlayer
  for (let i = 0; i < (7 - rowID); i++){
    if (chessArray[rowID + i + 1][colID].color === ""){
      chessArray[rowID + i + 1][colID].color = "green"
      noStepCounter = noStepCounter + 1
    } else {
      if (chessArray[rowID + i + 1][colID].color !== currentPlayer){
        chessArray[rowID + i + 1][colID].color = "green"
        noStepCounter = noStepCounter + 1
      }
      break
    }
  }
  for (let i = 0; i < rowID; i++){
    if (chessArray[rowID - i - 1][colID].color === ""){
      chessArray[rowID - i - 1][colID].color = "green"
      noStepCounter = noStepCounter + 1
    } else {
      if (chessArray[rowID - i - 1][colID].color !== currentPlayer){
        chessArray[rowID - i - 1][colID].color = "green"
        noStepCounter = noStepCounter + 1
      }
      break
    }
  }
  for (let i = 0; i < (7 - colID); i++){
    if (chessArray[rowID][colID + i + 1].color === ""){
      chessArray[rowID][colID + i + 1].color = "green"
      noStepCounter = noStepCounter + 1
    } else {
      if (chessArray[rowID][colID + i + 1].color !== currentPlayer){
        chessArray[rowID][colID + i + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      break
    }
  }
  for (let i = 0; i < colID; i++){
    if (chessArray[rowID][colID - i - 1].color === ""){
      chessArray[rowID][colID - i - 1].color = "green"
      noStepCounter = noStepCounter + 1
    } else {
      if (chessArray[rowID][colID - i - 1].color !== currentPlayer){
        chessArray[rowID][colID - i - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      break
    }
  }
  return {noStepCounter: noStepCounter}
}

function bishopRule(state: IChessState, noStepCounter: number, rowID: number, colID: number){
  let chessArray = state.chessArray
  let currentPlayer = state.currentPlayer
  let upLeftNum = rowID;
  if (colID < rowID){
    upLeftNum = colID 
  }
  for (let i = 0; i < upLeftNum; i++){
    if (chessArray[rowID - i - 1][colID - i - 1].color === ""){
      chessArray[rowID - i - 1][colID - i - 1].color = "green"
      noStepCounter = noStepCounter + 1
    } else {
      if (chessArray[rowID - i - 1][colID - i - 1].color !== currentPlayer){
        chessArray[rowID - i - 1][colID - i - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      break
    }
  }
  let upRightNum = rowID
  if ((7 - colID) < rowID){
    upRightNum = 7 - colID
  }
  for (let i = 0; i < upRightNum; i++){
    if (chessArray[rowID - i - 1][colID + i + 1].color === ""){
      chessArray[rowID - i - 1][colID + i + 1].color = "green"
      noStepCounter = noStepCounter + 1
    } else {
      if (chessArray[rowID - i - 1][colID + i + 1].color !== currentPlayer){
        chessArray[rowID - i - 1][colID + i + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      break
    }
  }
  let downRightNum = rowID
  if (colID > rowID){
    downRightNum = colID 
  }
  for (let i = 0; i < (7 - downRightNum); i++){
    if (chessArray[rowID + i + 1][colID + i + 1].color === ""){
      chessArray[rowID + i + 1][colID + i + 1].color = "green"
      noStepCounter = noStepCounter + 1
    } else {
      if (chessArray[rowID + i + 1][colID + i + 1].color !== currentPlayer){
        chessArray[rowID + i + 1][colID + i + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      break
    }
  }
  let downLeftNum = 7 - rowID
  if (colID < 7 - rowID){
    downLeftNum = colID
  }
  for (let i = 0; i < downLeftNum; i++){
    if (chessArray[rowID + i + 1][colID - i - 1].color === ""){
      chessArray[rowID + i + 1][colID - i - 1].color = "green"
      noStepCounter = noStepCounter + 1
    } else {
      if (chessArray[rowID + i + 1][colID - i - 1].color !== currentPlayer){
        chessArray[rowID + i + 1][colID - i - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      break
    }
  }
  return {noStepCounter: noStepCounter}
}

function prediction(state: IChessState, rowID: number, colID: number){
  let chessArray = state.chessArray
  let currentPlayer = state.currentPlayer
  let whiteKingMove = state.whiteKingMove
  let blackKingMove = state.blackKingMove
  let history = state.history
  let noStepCounter = 0;
  if (chessArray[rowID][colID].name === "wpawn"){
    if (rowID - 1 >= 0 && chessArray[rowID - 1][colID].color === ""){
      chessArray[rowID - 1][colID].color = "green"
      noStepCounter = noStepCounter + 1
    }
    if (rowID === 6 && chessArray[rowID - 2][colID].color === ""){
      chessArray[rowID - 2][colID].color = "green"
      noStepCounter = noStepCounter + 1
    }
    if (colID - 1 >= 0 && chessArray[rowID - 1][colID - 1].color !== currentPlayer && chessArray[rowID - 1][colID - 1].color !== ""){
      chessArray[rowID - 1][colID - 1].color = "green"
      noStepCounter = noStepCounter + 1
    } 
    if (colID + 1 <= 7 && chessArray[rowID - 1][colID + 1].color !== currentPlayer && chessArray[rowID - 1][colID + 1].color !== ""){
      chessArray[rowID - 1][colID + 1].color = "green"
      noStepCounter = noStepCounter + 1
    } 
    if (rowID === 3){
      if (colID + 1 <= 7 && chessArray[rowID][colID + 1].name === "bpawn" && chessArray[rowID - 2][colID + 1].name === "" && history[history.length - 2].chessArray[rowID - 2][colID + 1].name === "bpawn"){
        chessArray[rowID][colID + 1].color = "green"
        chessArray[rowID - 1][colID + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID - 1 >= 0 && chessArray[rowID][colID - 1].name === "bpawn" && chessArray[rowID - 2][colID - 1].name === "" && history[history.length - 2].chessArray[rowID - 2][colID - 1].name === "bpawn"){
        chessArray[rowID][colID - 1].color = "green"
        chessArray[rowID - 1][colID - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
  }
  if (chessArray[rowID][colID].name === "bpawn"){
    if (rowID + 1 <= 7 && chessArray[rowID + 1][colID].color === ""){
      chessArray[rowID + 1][colID].color = "green"
      noStepCounter = noStepCounter + 1
    }
    if (rowID === 1 && chessArray[rowID + 2][colID].color === ""){
      chessArray[rowID + 2][colID].color = "green"
      noStepCounter = noStepCounter + 1
    }
    if (colID - 1 >= 0 && chessArray[rowID + 1][colID - 1].color !== currentPlayer && chessArray[rowID + 1][colID - 1].color !== ""){
      chessArray[rowID + 1][colID - 1].color = "green"
      noStepCounter = noStepCounter + 1
    } 
    if (colID + 1 <= 7 && chessArray[rowID + 1][colID + 1].color !== currentPlayer && chessArray[rowID + 1][colID + 1].color !== ""){
      chessArray[rowID + 1][colID + 1].color = "green"
      noStepCounter = noStepCounter + 1
    } 
    if (rowID === 4){
      if (colID + 1 <= 7 && chessArray[rowID][colID + 1].name === "wpawn" && chessArray[rowID + 2][colID + 1].name === "" && history[history.length - 2].chessArray[rowID + 2][colID + 1].name === "wpawn"){
        chessArray[rowID][colID + 1].color = "green"
        chessArray[rowID + 1][colID + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID - 1 >= 0 && chessArray[rowID][colID - 1].name === "wpawn" && chessArray[rowID + 2][colID - 1].name === "" && history[history.length - 2].chessArray[rowID + 2][colID - 1].name === "wpawn"){
        chessArray[rowID][colID - 1].color = "green"
        chessArray[rowID + 1][colID - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
  }
  if (chessArray[rowID][colID].name.includes("rook")){
    let ruleResult = rookRule(state, noStepCounter, rowID, colID)
    noStepCounter = ruleResult.noStepCounter
  }
  if (chessArray[rowID][colID].name.includes("knight")){
    if (rowID - 2 >= 0){
      if (colID + 1 <= 7 && chessArray[rowID - 2][colID + 1].color !== currentPlayer){
        chessArray[rowID - 2][colID + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID - 1 >= 0 && chessArray[rowID - 2][colID - 1].color !== currentPlayer){
        chessArray[rowID - 2][colID - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
    if (rowID + 2 <= 7){
      if (colID + 1 <= 7 && chessArray[rowID + 2][colID + 1].color !== currentPlayer){
        chessArray[rowID + 2][colID + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID - 1 >= 0 && chessArray[rowID + 2][colID - 1].color !== currentPlayer){
        chessArray[rowID + 2][colID - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
    if (colID - 2 >= 0){
      if (rowID + 1 <= 7 && chessArray[rowID + 1][colID - 2].color !== currentPlayer){
        chessArray[rowID + 1][colID - 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (rowID - 1 >= 0 && chessArray[rowID - 1][colID - 2].color !== currentPlayer){
        chessArray[rowID - 1][colID - 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
    if (colID + 2 <= 7){
      if (rowID + 1 <= 7 && chessArray[rowID + 1][colID + 2].color !== currentPlayer){
        chessArray[rowID + 1][colID + 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (rowID - 1 >= 0 && chessArray[rowID - 1][colID + 2].color !== currentPlayer){
        chessArray[rowID - 1][colID + 2].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
  }
  if (chessArray[rowID][colID].name.includes("bishop")){
    let ruleResult = bishopRule(state, noStepCounter, rowID, colID)
    noStepCounter = ruleResult.noStepCounter
  }
  if (chessArray[rowID][colID].name.includes("queen")){
    let ruleResult1 = rookRule(state, noStepCounter, rowID, colID)
    noStepCounter = ruleResult1.noStepCounter
    let ruleResult = bishopRule(state, noStepCounter, rowID, colID)
    noStepCounter = noStepCounter + ruleResult.noStepCounter
  }
  if (chessArray[rowID][colID].name.includes("king")){
    if (rowID - 1 >= 0){
      if (chessArray[rowID - 1][colID].color !== currentPlayer){
        chessArray[rowID - 1][colID].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID - 1 >= 0 && chessArray[rowID - 1][colID - 1].color !== currentPlayer){
        chessArray[rowID - 1][colID - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID + 1 <= 7 && chessArray[rowID - 1][colID + 1].color !== currentPlayer){
        chessArray[rowID - 1][colID + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
    if (rowID + 1 <= 7){
      if (chessArray[rowID + 1][colID].color !== currentPlayer){
        chessArray[rowID + 1][colID].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID - 1 >= 0 && chessArray[rowID + 1][colID - 1].color !== currentPlayer){
        chessArray[rowID + 1][colID - 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
      if (colID + 1 <= 7 && chessArray[rowID + 1][colID + 1].color !== currentPlayer){
        chessArray[rowID + 1][colID + 1].color = "green"
        noStepCounter = noStepCounter + 1
      }
    }
    if (colID - 1 >= 0 && chessArray[rowID][colID - 1].color !== currentPlayer){
      chessArray[rowID][colID - 1].color = "green"
      noStepCounter = noStepCounter + 1
      if (chessArray[rowID][colID].name === 'wking' && whiteKingMove === 0 && state.leftWhiteRookMove === 0 && chessArray[rowID][colID - 4].name === 'wrook'){
        if (state.leftWhiteRookMove === 0 && chessArray[rowID][colID - 1].name === "" && chessArray[rowID][colID - 2].name === "" && chessArray[rowID][colID - 3].name === ""){
          chessArray[rowID][colID - 2].color = "green"
        }
      }
      if (chessArray[rowID][colID].name === 'bking' && blackKingMove === 0 && state.leftBlackRookMove === 0  && chessArray[rowID][colID - 4].name === 'brook'){
        if (state.leftBlackRookMove === 0 && chessArray[rowID][colID - 1].name === "" && chessArray[rowID][colID - 2].name === "" && chessArray[rowID][colID - 3].name === ""){
          chessArray[rowID][colID - 2].color = "green"
        }
      }
    }
    if (colID + 1 <= 7 && chessArray[rowID][colID + 1].color !== currentPlayer){
      chessArray[rowID][colID + 1].color = "green"
      noStepCounter = noStepCounter + 1
      if (chessArray[rowID][colID].name === 'wking' && whiteKingMove === 0 && state.rightWhiteRookMove === 0 && chessArray[rowID][colID + 3].name === 'wrook'){
        if (state.rightWhiteRookMove === 0 && chessArray[rowID][colID + 1].name === "" && chessArray[rowID][colID + 2].name === ""){
          chessArray[rowID][colID + 2].color = "green"
        }
      }
      if (chessArray[rowID][colID].name === 'bking' && blackKingMove === 0 && state.rightBlackRookMove === 0 && chessArray[rowID][colID + 3].name === 'brook'){
        if (state.rightBlackRookMove === 0 && chessArray[rowID][colID + 1].name === "" && chessArray[rowID][colID + 2].name === ""){
          chessArray[rowID][colID + 2].color = "green"
        }
      }
    }
  }

  if (noStepCounter === 0){
    console.log('no step: ', noStepCounter)
    wrongSound.play()
    return
  }
  state.currentChess = {
    name: chessArray[rowID][colID].name,
    row: rowID,
    col: colID
  }
  state.clickedMode = true
}

function play(state: IChessState, rowID: number, colID: number): void {
  let chessArray = state.chessArray
  let currentPlayer = state.currentPlayer
  let currentChess = state.currentChess
  let whiteKingMove = state.whiteKingMove
  let leftWhiteRookMove = state.leftWhiteRookMove
  let rightWhiteRookMove = state.rightWhiteRookMove
  let blackKingMove = state.blackKingMove
  let leftBlackRookMove = state.leftBlackRookMove
  let rightBlackRookMove = state.rightBlackRookMove

  if (state.viewMode === true){return}
  if (state.clickedMode === false){
    if (chessArray[rowID][colID].color !== currentPlayer || chessArray[rowID][colID].name === ""){
      wrongSound.play()
      return
    }
    prediction(state, rowID, colID)
  } else {
    let whiteCounter = 64
    let blackCounter = 64
    if (chessArray[rowID][colID].color === "green"){
      if (currentChess.name === 'wpawn' && currentChess.row === 3 && colID !== currentChess.col){ 
        if (chessArray[rowID][colID].name === '' && rowID === 2 && ((colID === currentChess.col + 1) || (colID === currentChess.col - 1))){
          chessArray[rowID][colID].color = currentPlayer
          chessArray[rowID][colID].name = currentChess.name
          chessArray[rowID + 1][colID].color = ""
          chessArray[rowID + 1][colID].name = ""
          chessArray[currentChess.row][currentChess.col].color = ""
          chessArray[currentChess.row][currentChess.col].name = ""
        } else if (chessArray[rowID][colID].name === 'bpawn' && rowID === 3 && ((colID === currentChess.col + 1) || (colID === currentChess.col - 1))){
          chessArray[rowID][colID].color = ""
          chessArray[rowID][colID].name = ""
          chessArray[rowID - 1][colID].color = currentPlayer
          chessArray[rowID - 1][colID].name = currentChess.name
          chessArray[currentChess.row][currentChess.col].color = ""
          chessArray[currentChess.row][currentChess.col].name = ""
          rowID = rowID - 1
        } else {
          chessArray[rowID][colID].color = currentPlayer
          chessArray[rowID][colID].name = currentChess.name
          chessArray[currentChess.row][currentChess.col].color = ""
          chessArray[currentChess.row][currentChess.col].name = ""
        }
      } else if (currentChess.name === 'bpawn' && currentChess.row === 4){
        if (chessArray[rowID][colID].name === '' && rowID === 5 && (colID === currentChess.col + 1 || colID === currentChess.col - 1)){
          chessArray[rowID][colID].color = currentPlayer
          chessArray[rowID][colID].name = currentChess.name
          chessArray[rowID - 1][colID].color = ""
          chessArray[rowID - 1][colID].name = ""
          chessArray[currentChess.row][currentChess.col].color = ""
          chessArray[currentChess.row][currentChess.col].name = ""
        } else if (chessArray[rowID][colID].name === 'wpawn' && rowID === 4 && (colID === currentChess.col + 1 || colID === currentChess.col - 1)){
          chessArray[rowID][colID].color = ""
          chessArray[rowID][colID].name = ""
          chessArray[rowID + 1][colID].color = currentPlayer
          chessArray[rowID + 1][colID].name = currentChess.name
          chessArray[currentChess.row][currentChess.col].color = ""
          chessArray[currentChess.row][currentChess.col].name = ""
          rowID = rowID + 1
        } else {
          chessArray[rowID][colID].color = currentPlayer
          chessArray[rowID][colID].name = currentChess.name
          chessArray[currentChess.row][currentChess.col].color = ""
          chessArray[currentChess.row][currentChess.col].name = ""
        }
      } else {
        chessArray[rowID][colID].color = currentPlayer
        chessArray[rowID][colID].name = currentChess.name
        chessArray[currentChess.row][currentChess.col].color = ""
        chessArray[currentChess.row][currentChess.col].name = ""
      }
      if (whiteKingMove === 0 && currentChess.name === 'wking'){
        if (leftWhiteRookMove === 0 && rowID === 7 && colID === 2){
          chessArray[7][3].name = 'wrook'
          chessArray[7][3].color = 'white'
          chessArray[7][0].name = ""
          chessArray[7][0].color = ""
        }
        if (rightWhiteRookMove === 0 && rowID === 7 && colID === 6){
          chessArray[7][5].name = 'wrook'
          chessArray[7][5].color = 'white'
          chessArray[7][7].name = ""
          chessArray[7][7].color = ""
        }
        state.whiteKingMove = 1
      }
      if (blackKingMove === 0 && currentChess.name === 'bking'){
        if (leftBlackRookMove === 0 && rowID === 0 && colID === 2){
          chessArray[0][3].name = 'brook'
          chessArray[0][3].color = 'black'
          chessArray[0][0].name = ""
          chessArray[0][0].color = ""
        }
        if (rightBlackRookMove === 0 && rowID === 0 && colID === 6){
          chessArray[0][5].name = 'brook'
          chessArray[0][5].color = 'black'
          chessArray[0][7].name = ""
          chessArray[0][7].color = ""
        }
        state.blackKingMove = 1
      }
      if (currentChess.name.includes('rook')){
        if (leftWhiteRookMove === 0 && currentChess.row === 7 && currentChess.col === 0){
          state.leftWhiteRookMove = 1
        }
        if (rightWhiteRookMove === 0 && currentChess.row === 7 && currentChess.col === 7){
          state.rightWhiteRookMove = 1
        }
        if (leftBlackRookMove === 0 && currentChess.row === 0 && currentChess.col === 0){
          state.leftBlackRookMove = 1
        }
        if (rightBlackRookMove === 0 && currentChess.row === 0 && currentChess.col === 7){
          state.rightBlackRookMove = 1
        }
      }
      if (currentChess.name !== 'wrook'){
        if (leftWhiteRookMove === 0 && rowID === 7 && colID === 0){
          state.leftWhiteRookMove = 1
        }
        if (rightWhiteRookMove === 0 && rowID === 7 && colID === 7){
          state.rightWhiteRookMove = 1
        }
        if (leftBlackRookMove === 0 && rowID === 0 && colID === 0){
          state.leftBlackRookMove = 1
        }
        if (rightBlackRookMove === 0 && rowID === 0 && colID === 7){
          state.rightBlackRookMove = 1
        }
      }
      state.lastChess = {row: rowID, col: colID}
      let result = clearMode(state, whiteCounter, blackCounter, rowID, colID)
      clickSound.play()
      if (currentChess.name === "wpawn" && rowID === 0){
        state.promotionMode = true
        return
      }
      if (currentChess.name === "bpawn" && rowID === 7){
        state.promotionMode = true
        return
      }
      state.history.push({
        currentPlayer: currentPlayer, 
        row: rowID, 
        col: colID, 
        leftWhiteRookMove: state.leftWhiteRookMove,
        rightWhiteRookMove: state.rightWhiteRookMove,
        leftBlackRookMove: state.leftBlackRookMove,
        rightBlackRookMove: state.rightBlackRookMove,
        whiteKingMove: state.whiteKingMove,
        blackKingMove: state.blackKingMove,
        chessArray: [...chessArray]
      })
      if (currentPlayer === 'white' && result.isEnd !== true){
        state.currentPlayer = 'black'
      } else {
        state.currentPlayer = 'white'
      }
      if (result.isEnd === true){
        state.winner = state.currentPlayer
        state.savedChessResult.push({
          history: state.history,
          time: ((new Date()).toLocaleTimeString() + " " + (new Date()).toLocaleDateString()),
          winner: state.currentPlayer
        })
        localStorage.setItem('Chess History', JSON.stringify(state.savedChessResult))
      } else {
        state.term = state.term + 1
      }
    } else if (chessArray[rowID][colID].color === currentPlayer && (currentChess.col !== colID || currentChess.row !== rowID)){
      clearMode(state, whiteCounter, blackCounter, rowID, colID)
      prediction(state, rowID, colID)
    } else {
      clearMode(state, whiteCounter, blackCounter, rowID, colID)
    }
  }
}

function clearMode(state: IChessState, whiteCounter: number, blackCounter: number, rowID: number, colID: number){
  let chessArray = state.chessArray
  for (let i = 0; i < 8; i++){
    for (let j = 0; j < 8; j++){
      if (chessArray[i][j].name !== 'bking'){
        blackCounter = blackCounter - 1
      } else {
        state.blackKingPosition = {row: i, col: j}
      }
      if (chessArray[i][j].name !== 'wking'){
        whiteCounter = whiteCounter - 1
      } else {
        state.whiteKingPosition = {row: i, col: j}
      }
      if (chessArray[i][j].color === "green"){
        if (chessArray[i][j].name === ""){
          chessArray[i][j].color = ""
        } else {
          if (state.currentPlayer === "white"){
            chessArray[i][j].color = "black"
          } else {
            chessArray[i][j].color = "white"
          }
        }
      }
    }
  }
  if (whiteCounter === 0){
    state.viewMode = true
    state.settingMode = true
    return {isEnd: true}
  }
  if (blackCounter === 0){
    state.viewMode = true
    state.settingMode = true
    return {isEnd: true}
  }
  state.clickedMode = false
  state.currentChess = {name:"", row: rowID, col: colID}
  return {isEnd: false}
}

function pawnPromotion(state: IChessState, chess: 'queen' | 'bishop' | 'knight' | 'rook'){
  let chessArray = state.chessArray
  let currentChess = state.currentChess
  let currentPlayer = state.currentPlayer
  let history = state.history
  let whiteCounter = 64
  let blackCounter = 64
  chessArray[currentChess.row][currentChess.col].name = currentPlayer === "white" ? "w" + chess : "b" + chess
  state.promotionMode = false
  state.term = state.term + 1
  history.push({
    currentPlayer: currentPlayer, 
    row: currentChess.row, 
    col: currentChess.col, 
    leftWhiteRookMove: state.leftWhiteRookMove,
    rightWhiteRookMove: state.rightWhiteRookMove,
    leftBlackRookMove: state.leftBlackRookMove,
    rightBlackRookMove: state.rightBlackRookMove,
    whiteKingMove: state.whiteKingMove,
    blackKingMove: state.blackKingMove,
    chessArray: [...chessArray]})
  let result = clearMode(state, whiteCounter, blackCounter, currentChess.row, currentChess.col)
  if (currentPlayer === 'white' && result.isEnd !== true){
    state.currentPlayer = 'black'
  } else {
    state.currentPlayer = 'white'
  }
}

function giveUp(state: IChessState){
  let currentChess = state.currentChess
  if (state.term === 1){
    alert(`You have not started the game!`)
    return
  }
  if (state.currentPlayer === "black"){
    state.winner = "white"
  } else {
    state.winner = "black"
  }
  state.currentPlayer = state.winner
  clearMode(state, 64, 64, currentChess.row, currentChess.col)
  state.viewMode = true
  state.savedChessResult.push({
    history: state.history,
    time: ((new Date()).toLocaleTimeString() + " " + (new Date()).toLocaleDateString()),
    winner: state.winner
  })
  localStorage.setItem('Chess History', JSON.stringify(state.savedChessResult))
}

function withdrawal(state: IChessState){
  let history = state.history
  if (state.term < 3){return}
  state.chessArray = history[history.length - 2].chessArray
  state.leftWhiteRookMove = history[history.length - 2].leftWhiteRookMove
  state.rightWhiteRookMove = history[history.length - 2].rightWhiteRookMove
  state.leftBlackRookMove = history[history.length - 2].leftBlackRookMove
  state.rightBlackRookMove = history[history.length - 2].rightBlackRookMove
  state.whiteKingMove = history[history.length - 2].whiteKingMove
  state.blackKingMove = history[history.length - 2].blackKingMove
  state.term = state.term - 1
  state.lastChess = {row: history[history.length - 2].row, col: history[history.length - 2].col}
  state.settingMode = false
  state.currentPlayer === "white" ? state.currentPlayer = "black" : state.currentPlayer = "white"
  history.pop()
}

export const ChessReducer = produce((
  state: IChessState = initialState,
  action: IChessAction,
): void =>{
  switch (action.type) {
    case '@@Chess/play':
      play(state, action.rowID, action.colID)
      return 
    
    case '@@Chess/setSettingMode':
      if (state.settingMode === true){
        state.settingMode = false
      } else {
        state.settingMode = true
      }
      return 

    case '@@Chess/setCheckReminder':
      state.checkReminder = action.boolean
      return 

    case '@@Chess/pawnPromotion':
      pawnPromotion(state, action.chess)
      return 

    case '@@Chess/giveUp':
      giveUp(state)
      return 

    case '@@Chess/withdrawal':
      withdrawal(state)
      return 
    
    case '@@Chess/deleteChessHistory': 
      let newArray = state.savedChessResult
      newArray.splice(action.id - 1, 1)
      state.savedChessResult = newArray
      localStorage.setItem('Chess History', JSON.stringify(state.savedChessResult))
      return

    case '@@Chess/viewChessHistory': 
      state.history = state.savedChessResult[action.id - 1].history
      state.currentPlayer = state.savedChessResult[action.id - 1].history[state.savedChessResult[action.id - 1].history.length -1].currentPlayer
      state.chessArray = state.savedChessResult[action.id - 1].history[state.savedChessResult[action.id - 1].history.length -1].chessArray
      state.lastChess = {row: state.savedChessResult[action.id - 1].history[state.savedChessResult[action.id - 1].history.length -1].row, col: state.savedChessResult[action.id - 1].history[state.savedChessResult[action.id - 1].history.length -1].col}
      state.viewMode = true
      state.gameStart = true
      state.settingMode = false
      state.term = state.savedChessResult[action.id - 1].history.length
      state.winner = state.savedChessResult[action.id - 1].winner
      return

    case '@@Chess/showRecordedChessArray':
      state.currentPlayer = state.history[action.num - 1].currentPlayer
      state.chessArray = state.history[action.num - 1].chessArray
      state.lastChess = {row: state.history[action.num - 1].row, col: state.history[action.num - 1].col}
      state.term = action.num
      return 

    case '@@Chess/init':
      let chessHistory = localStorage.getItem('Chess History')
      let chessHistoryInJS = [];
      if (chessHistory !== null){
        chessHistoryInJS = JSON.parse(chessHistory)
      }
      state.savedChessResult = chessHistoryInJS
      return 

    case '@@Chess/changeTheme':
      if (state.chessTheme === true){
        state.chessTheme = false
      } else {
        state.chessTheme = true
      }
      return 

    case '@@Chess/changeDirection':
      state.direction = action.direction
      return 

    case '@@Chess/changeHiddenBar':
      if (state.hiddenBar === true){
        state.hiddenBar = false
      } else {
        state.hiddenBar = true
      }
      return 
  
    case '@@Chess/makeDrawRequest':
      state.drawMode = state.drawMode + 1
      return 

    case '@@Chess/replyDrawRequest':
      state.drawMode = 0
      state.viewMode = true
      state.winner = "draw"
      state.savedChessResult.push({
        history: state.history,
        time: ((new Date()).toLocaleTimeString() + " " + (new Date()).toLocaleDateString()),
        winner: "draw"
      })
      localStorage.setItem('Chess History', JSON.stringify(state.savedChessResult))
      return 

    case '@@Chess/reset':
      state.gameStart = true
      state.settingMode = false
      state.term = 1
      state.clickedMode = false
      state.currentPlayer = "white"
      state.currentChess = {name:"", row: 0, col: 0}
      state.lastChess = {row: 0, col: 0}
      state.whiteKingPosition = {row: 0, col: 0}
      state.blackKingPosition = {row: 0, col: 0}
      state.viewMode = false
      state.promotionMode = false
      state.checkReminder = false
      state.leftWhiteRookMove = 0
      state.rightWhiteRookMove = 0
      state.leftBlackRookMove = 0
      state.rightBlackRookMove = 0
      state.whiteKingMove = 0
      state.blackKingMove = 0
      state.chessArray = [
        [{name: "brook", color: "black"}, {name: "bknight", color: "black"}, {name: "bbishop", color: "black"},{name: "bqueen", color: "black"},{name: "bking", color: "black"},{name: "bbishop", color: "black"},{name: "bknight", color: "black"},{name: "brook", color: "black"}],
        [{name: "bpawn", color: "black"}, {name: "bpawn", color: "black"}, {name: "bpawn", color: "black"},{name: "bpawn", color: "black"},{name: "bpawn", color: "black"},{name: "bpawn", color: "black"},{name: "bpawn", color: "black"},{name: "bpawn", color: "black"}],
        [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""}],
        [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""}],
        [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""}],
        [{name: "", color: ""}, {name: "", color: ""}, {name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""},{name: "", color: ""}],
        [{name: "wpawn", color: "white"}, {name: "wpawn", color: "white"}, {name: "wpawn", color: "white"},{name: "wpawn", color: "white"},{name: "wpawn", color: "white"},{name: "wpawn", color: "white"},{name: "wpawn", color: "white"},{name: "wpawn", color: "white"}],
        [{name: "wrook", color: "white"}, {name: "wknight", color: "white"}, {name: "wbishop", color: "white"},{name: "wqueen", color: "white"},{name: "wking", color: "white"},{name: "wbishop", color: "white"},{name: "wknight", color: "white"},{name: "wrook", color: "white"}],
      ]
      state.history = []
      state.drawMode = 0
      delete state.winner
      return 

  }
}, initialState)