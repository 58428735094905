export function userLogin() {
  return {
    type: '@@Login/login' as const,
  }
}

export function userRegistration() {
  return {
    type: '@@Login/registration' as const,
  }
}

export function userLogout() {
  return {
    type: '@@Login/logout' as const,
  }
}

export function load_token(token: string){
  return {
    type: '@@Login/load_token' as const,
    token,
  }
}

type FAILED_INTENT = 
  | "@@Login/login_FAILED"
  | "@@Login/logout_FAILED"
  | "@@Login/registration_FAILED"
  | "@@Login/load_token_FAILED"

export function failed(type: FAILED_INTENT, msg: string){
  return {
    type,
    msg
  }
}

export type ILoginAction = 
  | ReturnType<typeof userLogin>
  | ReturnType<typeof userLogout>
  | ReturnType<typeof userRegistration>
  | ReturnType<typeof load_token>
