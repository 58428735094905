export function reset() {
  return {
    type: '@@Reversi/reset' as const,
  }
}

export function play(rowID: number, colID: number) {
  return {
    type: '@@Reversi/play' as const,
    rowID,
    colID
  }
}

export function result(blackNum: number, whiteNum: number) {
  return {
    type: '@@Reversi/result' as const,
    blackNum,
    whiteNum
  }
}

export function save() {
  return {
    type: '@@Reversi/save' as const,
  }
}

export function viewReversiHistory(id: number) {
  return {
    type: '@@Reversi/view-history' as const,
    id
  }
}

export function delReversiHistory(id: number) {
  return {
    type: '@@Reversi/delete-history' as const,
    id
  }
}

export function pass() {
  return {
    type: '@@Reversi/pass' as const,
  }
}

export function makingReversiHistoryList() {
  return {
    type: '@@Reversi/make-history-list' as const,
  }
}

export type IReversiAction =
  | ReturnType<typeof reset>
  | ReturnType<typeof result>
  | ReturnType<typeof save>
  | ReturnType<typeof viewReversiHistory>
  | ReturnType<typeof delReversiHistory>
  | ReturnType<typeof pass>
  | ReturnType<typeof play>
  | ReturnType<typeof makingReversiHistoryList>