import { IRootThunkDispatch } from '../store';
import { failed, userLogin, userRegistration } from './action';

const { REACT_APP_API_SERVER } = process.env


export function loginThunk(username: string, password: string) {
  console.log('received, username: ', username, ', password: ', password)

  return async (thunkDispatch: IRootThunkDispatch) => {
    const res = await fetch(`${REACT_APP_API_SERVER}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({ username: username, password: password }),
    });
    const result = await res.json();
    console.log('result: ', result)
    if (result.token) {
      console.log('token: ', result.token)
      localStorage.setItem('token',result.token);
      thunkDispatch(userLogin());
    } else {
      // thunkDispatch(failed("@@Login/login_FAILED", result.msg))
      alert(result.msg)
    }
  }
}

export function registrationThunk(username: string, password: string){

  console.log('received registration request, username: ', username, ', password: ', password)
  return async (thunkDispatch: IRootThunkDispatch) => {
    const res = await fetch(`${REACT_APP_API_SERVER}/registration`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({ username: username, password: password }),
    }); 
    const result = await res.json();
    console.log(result)
    // not yet complete route logic
    if (result.status === true) {
      console.log('dispatched')
      
      thunkDispatch(userRegistration());
    } else {
      // thunkDispatch(failed("@@Login/registration_FAILED", result.msg))
      alert('username has been already registered')
    }
  }
}
