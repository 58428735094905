import setting from '../../image/setting.png'
import arrowForward from '../../image/arrow-forward.svg';
import arrowDown from '../../image/arrow-down.svg';
import leftImg from '../../image/left.svg';
import rightImg from '../../image/right.svg';
import { chessImg, chessImg2 } from '../../Utility/type';
import { useDispatch } from 'react-redux';
import { changeDirection, changeHiddenBar, init } from '../../redux/Chess/action';
import { useEffect } from 'react';

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

export default function SideBar(
  props: {
    gameStart: boolean,
    term: number,
    currentPlayer: string,
    viewMode: boolean,
    history: any[],
    chessTheme?: boolean,
    chess?: "棋",
    direction: "up" | "left",
    hiddenBar: boolean,
    winner: string | undefined,
    line?: number,
    blackNum?: number,
    whiteNum?: number,
    onClickLineUpdate?: (e: any) => void,
    onClickBack: () => void,
    onClickNext: () => void,
    onClickSetting: () => void,
  }
){

  const gameStart = props.gameStart
  const term = props.term
  const currentPlayer = props.currentPlayer
  const viewMode = props.viewMode
  const history = props.history
  const chessTheme = props.chessTheme
  const direction = props.direction
  const hiddenBar = props.hiddenBar
  const winner = props.winner
  
  const dispatch = useDispatch()

  useEffect(()=>{
    if (windowWidth > windowHeight - 100){
      dispatch(changeDirection("left"))
    } else {
      dispatch(changeDirection("up"))
    }
    dispatch(init())
  }, [dispatch])
  
  return (
      <div style={{position: 'absolute', top: 0, left: 0, background: 'rgba(50, 50, 50, 0.6)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: direction === "left" ?'column' :'row', color: "white"}}>
        {hiddenBar ?null 
        :<>
          <div style={{margin: 10, display: 'flex', flexDirection: direction === "left" ?'column' :'row', alignItems: 'center', justifyContent: 'center'}}>
            <div>Term:</div>
            <div style={{display: 'flex', justifyContent: 'center', justifyItems: 'center', marginTop: direction === "left" ?10:0, marginLeft: direction === "left" ?0: 10}}>
              {viewMode
              ?<div className="chessImgBtn" style={{borderRadius: 5, paddingTop: 5}} onClick={props.onClickBack}>
                <img style={{filter: "invert(1)", width: 20, height: 20}} src={leftImg}/>
              </div>
              :null
              }
              <div style={{fontSize: 20}}>{term}</div>
              {viewMode && history.length !== term
              ?<div className="chessImgBtn" style={{borderRadius: 5, paddingTop: 5}} onClick={props.onClickNext}>
                <img style={{filter: "invert(1)", width: 20, height: 20}} src={rightImg}/>
              </div>
              :null  
              }
            </div>
          </div>
          <div style={{margin: 10, display: 'flex', flexDirection: direction === "left" ?'column' :'row', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{margin: 5}}>
              {winner === "draw" ?"Draw!" :viewMode && history.length === term ?"Winner: " :"Player:"}
            </div>
            <div>
              {chessTheme !== undefined
                ?<img style={{width: 40, height: 40, objectFit: chessTheme?'cover' :'none', objectPosition: 'top'}} src={chessTheme?chessImg[currentPlayer.slice(0, 1) + 'king'] :chessImg2[currentPlayer.slice(0, 1) + 'king']}/>
                :<>{props.line
                  ?<div className="player" style={{backgroundColor: currentPlayer}}></div>
                  :<div className={"CCPlayer"+ currentPlayer.slice(0, 1).toUpperCase() + currentPlayer.slice(1)} style={{color: currentPlayer}}>{props.chess}</div>
                }</>
              }
            </div>
          </div>
          <div style={{margin: 10, display: 'flex', flexDirection: direction === "left" ?'column' :'row', alignItems: 'center', justifyContent: 'center'}} className="chessImgBtn">
            {gameStart
            ?<div onClick={props.onClickSetting}>
              <img style={{width: 40, height: 40, paddingTop: 5}} src={setting}></img>
            </div>
            :null}
          </div>
          {props.line && props.onClickLineUpdate &&
          <>
            <label>Line Setting:</label> 
            <input style={{textAlign: "center"}} type="number" onChange={props.onClickLineUpdate} min="9" max="19" placeholder={`${props.line}`} />
          </>
          }
          <div style={{margin: 10, display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={()=>direction === "left" ?dispatch(changeDirection("up")) : dispatch(changeDirection("left"))}>
            <div style={{width: 30, height: 30}} className="chessImgBtn">
              <img style={{width: 30, height: 30, filter: "invert(1)"}} src={direction === "left" ?arrowForward :arrowDown}  />
            </div>
          </div>
        </>
        }
        <div style={{width: "100%"}}>
          <div className="hiddenBar" style={{minWidth: direction === "left" ?76 :0, minHeight: direction === "left" ?0 :76, height: 10, width: 10, backgroundColor: '#333333'}} onClick={()=>dispatch(changeHiddenBar())}></div>
        </div>
      </div>
    </div>
  )
}