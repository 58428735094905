export const classes = {
  chessBtn: {
    marginBottom: 20, 
    padding: "5px 30px", 
    borderRadius: 10, 
    fontSize: 20, 
    backgroundColor: "white", 
    color: "black",
    minWidth: 200,
  },
}