import FiveChess from '../FiveChess/FiveChess';
import Reversi from '../Reversi/Reversi';
import { push } from 'connected-react-router';
import { IRootState } from '../../redux/store';
import { Link, Route, Switch } from 'react-router-dom';
import NoMatch from '../NoMatch/NoMatch';
import HistoryList from '../HistoryList/HistoryList';
import ConnectFour from '../ConnectFour/ConnectFour';
import ChineseChess from '../ChineseChess/ChineseChess';
import login from '../Login/login';
import { useDispatch, useSelector } from 'react-redux';
import { load_token, userLogout } from '../../redux/Login/action';
import Registration from '../Registration/registration';
import { Todo } from '../Todo/Todo';
import { useEffect } from 'react';
import Chess from '../Chess/Chess';

export default function Landing(){

  
  const loginStatus = useSelector((state: IRootState)=>state.Login.loginStatus)
  const dispatch = useDispatch()
  
  function changeGame(e: any ){
    let links: any = document.querySelectorAll('.navbar a')
    for (let i = 0; i < links.length; i++){
      if (e.target.value === links[i].textContent){
        links[i].click();
      }
    }
  }

  // function clickLogout(){
  //   localStorage.removeItem('token');
  //   dispatch(userLogout())
  //   dispatch(push('/'));
  // }

  const token = localStorage.getItem('token');
  useEffect(()=>{
    if (token){
      dispatch(load_token(token))
    } else {
      dispatch(userLogout())
    }
  }, [token])
  
  return (
    <div className="container">
      <div className="navbar">
        <Link to="/FiveChess" className="link">Five Chess</Link>
        <Link to="/Reversi" className="link">Reversi</Link>
        <Link to="/ConnectFour" className="link">Connect Four</Link>
        <Link to="/ChineseChess" className="link">Chiness Chess</Link>
        <Link to="/Chess" className="link">Chess</Link>
        <Link to="/HistoryList" className="link">History</Link>
      </div>
      <div className="gameContainer">
        <Switch>
          <Route path="/" exact={true} component={Todo} />
          <Route path="/FiveChess"  component={FiveChess} />
          <Route path="/Reversi" component={Reversi} />
          <Route path="/ConnectFour" component={ConnectFour} />
          <Route path="/ChineseChess" component={ChineseChess} />
          <Route path="/Chess" component={Chess} />
          <Route path="/HistoryList" component={HistoryList} />
          <Route path="/Login" component={login} />
          <Route path="/Registration" component={Registration} />
          <Route component={NoMatch} />
        </Switch>
      </div>
      <div style={{height: "36px", backgroundColor: "silver", color: "grey", fontSize: 10, alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
          <span>Leo's Projects |</span><span>&nbsp;leoli20182000@outlook.com |</span><span>&nbsp;Copyright @ 2021</span>
        </div>
        <div className="floatNavbar">
          <select onChange={changeGame}>
            <option></option>
            <option>Five Chess</option>
            <option>Reversi</option>
            <option>Connect Four</option>
            <option>Chinese Chess</option>
            <option>Chess</option>
            <option>History</option>
          </select>
          {/* {
            loginStatus === false
            ? <button><Link to="/Login" className="loginBtn">Login</Link></button>
            : <button className="loginBtn" onClick={clickLogout}>Logout</button>
          } */}
        </div>
      </div>
    </div>
  )
}