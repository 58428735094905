export function reset() {
  return {
    type: '@@FiveChess/reset' as const,
  }
}

export function play(rowID: number, colID: number) {
  return {
    type: '@@FiveChess/play' as const,
    rowID,
    colID
  }
}

export function updateLine(lines: number){
  return {
    type: '@@FiveChess/update-line' as const,
    lines
  }
}

export function save(){
  return {
    type: '@@FiveChess/save' as const,
  }
}

export function makingFiveChessHistoryList() {
  return {
    type: '@@FiveChess/make-history-list' as const,
  }
}

export function viewFiveChessHistory(id: number) {
  return {
    type: '@@FiveChess/view-history' as const,
    id
  }
}

export function delFiveChessHistory(id: number) {
  return {
    type: '@@FiveChess/delete-history' as const,
    id
  }
}

type FAILED_INTENT = 
  | "@@FiveChess/reset_FAILED"
  | "@@FiveChess/play_FAILED"
  | "@@FiveChess/update-line_FAILED"
  | "@@FiveChess/save_FAILED"
  | "@@FiveChess/make-history-list_FAILED"
  | "@@FiveChess/view-history_FAILED"
  | "@@FiveChess/delete-history_FAILED"

export function failed(type: FAILED_INTENT, msg: string) {
    return {
        type,
        msg
    }
}

export type IFiveChessAction =
  | ReturnType<typeof reset>
  | ReturnType<typeof updateLine>
  | ReturnType<typeof play>
  | ReturnType<typeof save>
  | ReturnType<typeof makingFiveChessHistoryList>
  | ReturnType<typeof viewFiveChessHistory>
  | ReturnType<typeof delFiveChessHistory>
