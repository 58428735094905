export const chessImg: any = {
  brook: "https://cdn.pixabay.com/photo/2012/04/18/00/42/chess-36314__340.png",
  wrook: "https://cdn.pixabay.com/photo/2012/04/18/00/42/chess-36313__340.png",
  bknight: "https://cdn.pixabay.com/photo/2013/07/12/13/27/knight-147065__340.png",
  wknight: "https://cdn.pixabay.com/photo/2012/04/13/17/39/knight-33015__340.png",
  bbishop: "https://cdn.pixabay.com/photo/2012/04/18/00/40/chess-36305__340.png",
  wbishop: "https://cdn.pixabay.com/photo/2013/07/12/16/56/chess-151543__340.png",
  bqueen: "https://cdn.pixabay.com/photo/2012/04/18/00/42/chess-36311_960_720.png",
  wqueen: "https://cdn.pixabay.com/photo/2012/04/18/00/42/chess-36310_960_720.png",
  bking: "https://cdn.pixabay.com/photo/2012/04/18/00/41/chess-36308_960_720.png",
  wking: "https://cdn.pixabay.com/photo/2012/04/18/00/41/chess-36307_1280.png", 
  bpawn: "https://cdn.pixabay.com/photo/2013/07/12/13/27/pawn-147066__340.png",
  wpawn: "https://cdn.pixabay.com/photo/2013/07/12/16/56/chess-151542__340.png",
  // brook: brookImg,
  // wrook: wrookImg,
  // bknight: bknightImg,
  // wknight: wknightImg,
  // bbishop: bbishopImg,
  // wbishop: wbishopImg,
  // bqueen: bqueenImg,
  // wqueen: wqueenImg,
  // bking: bkingImg,
  // wking: wkingImg, 
  // bpawn: bpawnImg,
  // wpawn: wpawnImg,
  "": null
}

export const chessImg2: any = {
  brook: "https://images.vexels.com/media/users/3/143223/isolated/preview/2ac9bfd45b5f61c5425789214ce4ea4e-rook-chess-figure.png",
  wrook: "https://i.pinimg.com/originals/cc/f5/a5/ccf5a501510ff6e3ab0d400ee922f2ae.png",
  bknight: "https://images.vexels.com/media/users/3/143174/isolated/lists/34f1b2b61373f599d8f735e91ce37851-knight-chess-figure-black.png",
  wknight: "https://images.vexels.com/media/users/3/143173/isolated/lists/b34a47b2a0e9d773aa199f7ae0f0a7ca-knight-chess-figure.png",
  bbishop: "https://images.vexels.com/media/users/3/143271/isolated/lists/a787a26d69a5c257e7f05d7d416f9389-bishop-chess-figure.png",
  wbishop: "https://images.vexels.com/media/users/3/143272/isolated/lists/270aa40d920d040a999bc44d27a2e114-bishop-chess-figure-white.png",
  bqueen: "https://images.vexels.com/media/users/3/166479/isolated/lists/a67a141227a6b85d144f631a8a0e448e-queen-chess-piece.png",
  wqueen: "https://images.vexels.com/media/users/3/143217/isolated/lists/b37faca0051e61f5f393c949c1f4907e-queen-chess-figure-white.png",
  bking: "https://images.vexels.com/media/users/3/143268/isolated/lists/efccbb14ab51440300ce79d55dc23551-king-chess-figure-black.png",
  wking: "https://images.vexels.com/media/users/3/143267/isolated/lists/80c9ad2648bc76689e7167cfd68306e4-king-chess-figure.png",
  bpawn: "https://images.vexels.com/media/users/3/143289/isolated/lists/759a1d9598eae60232ca7a56b19f5a7d-pawn-chess-figure.png",
  wpawn: "https://images.vexels.com/media/users/3/143290/isolated/lists/3dc0e132939ad9ebff880b64637ea820-pawn-chess-figure-white.png",
  "": null
}

export const clickSound = new Audio('http://freesoundeffect.net/sites/default/files/game-piece-slide-1-sound-effect-99743653.mp3')
export const wrongSound = new Audio('https://www.sfxbuzz.com/jdownloads/Buzzer,%20Alarm%20and%20Siren%20Sound%20Effects/Wrong%20Clakson%20Sound%20Effect.mp3')
