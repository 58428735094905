import { useDispatch, useSelector } from 'react-redux'
import { delReversiHistory, makingReversiHistoryList, viewReversiHistory } from '../../redux/Reversi/action'
import { IRootState } from '../../redux/store'
import History from '../History/History'
import { push } from 'connected-react-router';
import './HistoryList.css';
import { useEffect } from 'react';
import { delFiveChessHistory, makingFiveChessHistoryList, viewFiveChessHistory } from '../../redux/FiveChess/action';
import { deleteChessHistory, init as chessInit, viewChessHistory } from '../../redux/Chess/action';
import { deleteCChessHistory, init as cChessInit, viewCChessHistory } from '../../redux/ChineseChess/action';

export default function HistoryList(){

  const savedReversiResult = useSelector((state: IRootState) => state.Reversi.savedReversiResult)
  const savedFiveChessResult = useSelector((state: IRootState) => state.FiveChess.savedFiveChessResult)
  const savedCChessResult = useSelector((state: IRootState) => state.CChess.savedChessResult)
  const savedChessResult = useSelector((state: IRootState) => state.Chess.savedChessResult)

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(makingReversiHistoryList())
    dispatch(makingFiveChessHistoryList())
    dispatch(chessInit())
    dispatch(cChessInit())
  }, [dispatch])

  function clickViewReversiHistory(id: number){
    dispatch(viewReversiHistory(id))
    dispatch(push('./Reversi'))
  }

  function clickDelReversiHistory(id: number){
    dispatch(delReversiHistory(id))
  }

  function clickViewFiveChessHistory(id: number){
    dispatch(viewFiveChessHistory(id))
    dispatch(push('./FiveChess'))
  }

  function clickDelFiveChessHistory(id: number){
    dispatch(delFiveChessHistory(id))
  }

  function clickViewChessHistory(id: number){
    dispatch(viewChessHistory(id))
    dispatch(push('./Chess'))
  }
  
  function clickDelChessHistory(id: number){
    dispatch(deleteChessHistory(id))
  }

  function clickViewCChessHistory(id: number){
    dispatch(viewCChessHistory(id))
    dispatch(push('./ChineseChess'))
  }
  
  function clickDelCChessHistory(id: number){
    dispatch(deleteCChessHistory(id))
  }

  let reversiHistory = []
  let fiveChessHistory = []
  let cChessHistory = []
  let chessHistory = []

  for (let i = 0; i < savedReversiResult.length; i++){
    let history = <History key={"reversi"+i} onViewClick={clickViewReversiHistory} onDelClick={clickDelReversiHistory} historyID={i+1} time={savedReversiResult[i].time} winner={savedReversiResult[i].winner} black={savedReversiResult[i].black} white={savedReversiResult[i].white}/>
    reversiHistory.unshift(history)
  }

  for (let i = 0; i < savedFiveChessResult.length; i++){
    let history = <History key={"fiveChess"+i} onViewClick={clickViewFiveChessHistory} onDelClick={clickDelFiveChessHistory} historyID={i+1} time={savedFiveChessResult[i].time} winner={savedFiveChessResult[i].winner} black={null} white={null}/>
    fiveChessHistory.unshift(history)
  }

  for (let i = 0; i < savedCChessResult.length; i++){
    let history = <History key={"chess"+i} onViewClick={clickViewCChessHistory} onDelClick={clickDelCChessHistory} historyID={i+1} time={savedCChessResult[i].time} winner={savedCChessResult[i].winner} black={null} white={null} term={savedCChessResult[i].history.length}/>
    cChessHistory.unshift(history)
  }

  for (let i = 0; i < savedChessResult.length; i++){
    let history = <History key={"chess"+i} onViewClick={clickViewChessHistory} onDelClick={clickDelChessHistory} historyID={i+1} time={savedChessResult[i].time} winner={savedChessResult[i].winner} black={null} white={null} term={savedChessResult[i].history.length}/>
    chessHistory.unshift(history)
  }

  return (
    <div className="historyContainer">
      <div className="HistoryLists">
        {
          fiveChessHistory.length === 0 ?null :
          <div className="HistoryList">
            <h2>Five Chess</h2>
            {fiveChessHistory}
          </div>
        }
        {
          reversiHistory.length === 0 ?null :
          <div className="HistoryList">
            <h2>Reversi</h2>
            {reversiHistory}
          </div>
        }
        {
          cChessHistory.length === 0 ?null :
          <div className="HistoryList">
            <h2>Chinese Chess</h2>
            {cChessHistory}
          </div>
        }
        {
          chessHistory.length === 0 ?null :
          <div className="HistoryList">
            <h2>Chess</h2>
            {chessHistory}
          </div>
        }
      </div>
    </div>
  )
}