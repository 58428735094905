import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { giveUp, makeDrawRequest, play, replyDrawRequest, reset, setCheckReminder, setSettingMode, showRecordedChessArray, withdrawal } from '../../redux/ChineseChess/action';
import { IRootState } from '../../redux/store';
import CheckReminder from '../Tools/CheckReminder';
import GiveUp from '../Tools/GiveUp';
import Setting from '../Tools/Setting';
import SideBar from '../Tools/SideBar';
import './ChineseChess.css'

export default function ChineseChess(){

  const gameStart = useSelector((state: IRootState) => state.CChess.gameStart)
  const settingMode = useSelector((state: IRootState) => state.CChess.settingMode)
  const term = useSelector((state: IRootState) => state.CChess.term)
  const currentPlayer = useSelector((state: IRootState) => state.CChess.currentPlayer)
  const lastChess = useSelector((state: IRootState) => state.CChess.lastChess)
  const redKingPosition = useSelector((state: IRootState) => state.CChess.redKingPosition)
  const blackKingPosition = useSelector((state: IRootState) => state.CChess.blackKingPosition)
  const viewMode = useSelector((state: IRootState) => state.CChess.viewMode)
  const checkReminder = useSelector((state: IRootState) => state.CChess.checkReminder)
  const chessArray = useSelector((state: IRootState) => state.CChess.chessArray)
  const history = useSelector((state: IRootState) => state.CChess.history)
  const drawMode = useSelector((state: IRootState) => state.CChess.drawMode)
  const winner = useSelector((state: IRootState) => state.CChess.winner)
  const direction = useSelector((state: IRootState) => state.Chess.direction)
  const hiddenBar = useSelector((state: IRootState) => state.Chess.hiddenBar)
  const [coordinate, setCoordinate] = useState<{row: null | number, col: null | number}>({row: null, col: null})
  const dispatch = useDispatch()

  useEffect(()=>{
    if (currentPlayer === "black"){
      checkCheck(currentPlayer, blackKingPosition.row, blackKingPosition.col)
    } else {
      checkCheck(currentPlayer, redKingPosition.row, redKingPosition.col)
    }
  }, [dispatch, term])

  function buildChessDom(){
    let Squares = [];
    for (let i = 0; i < 10; i++) {
      let rows = []
      for (let j = 0; j < 9; j++) {
        rows.push(
          <button 
            key={"row"+i+"column"+j} 
            className="CCBtn"
          >
            <div className={'CCChess' + chessArray[i][j].color}
              onClick={()=>{
                setCoordinate({row: null, col: null})
                clickPlay(i, j)
              }} 
              onDragStart={()=>{
                console.log("on drag")
                setCoordinate({row: null, col: null})
                clickPlay(i, j)
              }}
              onDragOver={()=>setCoordinate({row: i, col: j})}
              onDragEnd={()=>coordinate.row !== null && coordinate.col !== null && clickPlay(coordinate.row, coordinate.col)}
              draggable={true}
            >
              {chessArray[i][j].name}
            </div>
          </button>
        )
      }
      Squares.push(<div>{rows}</div>)
    }
    return Squares
  }

  function checkCheck(player: "black" | "red", row: number, col: number){
    console.log(row, col)
    if (viewMode === true || term === 1){return}
    let rival: "red" | "black" = "black"
    if (player === "black"){
      rival = "red"
    }
    let checkCounter = 0;
    let jumpCounter = 0;
    if (rival === "red"){
      for (let i = 0; i < (9 - row); i++){
        if (i === 0 && chessArray[row + i + 1][col].name === "兵"){
          checkCounter = 1
          break
        } else if (chessArray[row + i + 1][col].name === "俥" || chessArray[row + i + 1][col].name === "帥" && jumpCounter === 0){
          checkCounter = 1
          break
        } else if (chessArray[row + i + 1][col].name === "炮" && jumpCounter === -1){
          jumpCounter = 1
          checkCounter = 1
          break
        } else if (chessArray[row + i + 1][col].name !== "" && jumpCounter === 0){
          jumpCounter = -1
        } else if (chessArray[row + i + 1][col].name !== "" && jumpCounter === -1){
          jumpCounter = 0
          break
        }
      }
      jumpCounter = 0
      for (let i = 0; i < (row); i++){
        if (chessArray[row - i - 1][col].name === "俥" && jumpCounter === 0){
          checkCounter = 1
          break
        } else if (chessArray[row - i - 1][col].name === "炮" && jumpCounter === -1){
          jumpCounter = 1
          checkCounter = 1
          break
        } else if (chessArray[row - i - 1][col].name !== "" && jumpCounter === 0){
          jumpCounter = -1
        } else if (chessArray[row - i - 1][col].name !== "" && jumpCounter === -1){
          jumpCounter = 0
          break
        }
      }
      jumpCounter = 0
      for (let i = 1; i <= col; i++){
        if (i === 1 && chessArray[row][col - i].name === "兵"){
          checkCounter = 1
          break
        } else if (chessArray[row][col - i].name === "俥" && jumpCounter === 0){
          checkCounter = 1
          break
        } else if (chessArray[row][col - i].name === "炮" && jumpCounter === -1){
          jumpCounter = 1
          checkCounter = 1
          break
        } else if (chessArray[row][col - i].name !== "" && jumpCounter === 0){
          jumpCounter = -1
        } else if (chessArray[row][col - i].name !== "" && jumpCounter === -1){
          jumpCounter = 0
          break
        }
      }
      jumpCounter = 0
      for (let i = 1; i <= (8 - col); i++){
        if (i === 1 && chessArray[row][col + i].name === "兵"){
          checkCounter = 1
          break
        } else if (chessArray[row][col + i].name === "俥" && jumpCounter === 0){
          checkCounter = 1
          break
        } else if (chessArray[row][col + i].name === "炮" && jumpCounter === -1){
          jumpCounter = 1
          checkCounter = 1
          break
        } else if (chessArray[row][col + i].name !== "" && jumpCounter === 0){
          jumpCounter = -1
        } else if (chessArray[row][col + i].name !== "" && jumpCounter === -1){
          jumpCounter = 0
          break
        }
      }
      if (row - 2 >= 0){
        if (chessArray[row - 2][col - 1].name === "傌"){
          if (chessArray[row - 1][col - 1].color === ""){
            checkCounter = 1
          }
        }
        if (chessArray[row - 2][col + 1].name === "傌"){
          if (chessArray[row - 1][col + 1].color === ""){
            checkCounter = 1
          }
        }
      }
      if (chessArray[row + 2][col - 1].name === "傌"){
        if (chessArray[row + 1][col - 1].color === ""){
          checkCounter = 1
        }
      }
      if (chessArray[row + 2][col + 1].name === "傌"){
        if (chessArray[row + 1][col + 1].color === ""){
          checkCounter = 1
        }
      }
      if (row - 1 >= 0){
        if (chessArray[row - 1][col - 2].name === "傌"){
          if (chessArray[row - 1][col - 1].color === ""){
            checkCounter = 1
          }
        }
        if (chessArray[row - 1][col + 2].name === "傌"){
          if (chessArray[row - 1][col + 1].color === ""){
            checkCounter = 1
          }
        }
      }
      if (chessArray[row + 1][col - 2].name === "傌"){
        if (chessArray[row + 1][col - 1].color === ""){
          checkCounter = 1
        }
      }
      if (chessArray[row + 1][col + 2].name === "傌"){
        if (chessArray[row + 1][col + 1].color === ""){
          checkCounter = 1
        }
      }
    } else {
      for (let i = 0; i < (9 - row); i++){
        if (chessArray[row + i + 1][col].name === "車" && jumpCounter === 0){
          checkCounter = 1
          break
        } else if (chessArray[row + i + 1][col].name === "砲" && jumpCounter === -1){
          jumpCounter = 1
          checkCounter = 1
          break
        } else if (chessArray[row + i + 1][col].name !== "" && jumpCounter === 0){
          jumpCounter = -1
        } else if (chessArray[row + i + 1][col].name !== "" && jumpCounter === -1){
          jumpCounter = 0
          break
        }
      }
      jumpCounter = 0
      for (let i = 0; i < (row); i++){
        if (i === 0 && chessArray[row - i - 1][col].name === "卒"){
          checkCounter = 1
          break
        } else if (chessArray[row - i - 1][col].name === "車" || chessArray[row - i - 1][col].name === "將" && jumpCounter === 0){
          checkCounter = 1
          break
        } else if (chessArray[row - i - 1][col].name === "砲" && jumpCounter === -1){
          jumpCounter = 1
          checkCounter = 1
          break
        } else if (chessArray[row - i - 1][col].name !== "" && jumpCounter === 0){
          jumpCounter = -1
        } else if (chessArray[row - i - 1][col].name !== "" && jumpCounter === -1){
          jumpCounter = 0
          break
        }
      }
      jumpCounter = 0
      for (let i = 1; i <= col; i++){
        if (i === 1 && chessArray[row][col - i].name === "卒"){
          checkCounter = 1
          break
        } else if (chessArray[row][col - i].name === "車" && jumpCounter === 0){
          checkCounter = 1
          break
        } else if (chessArray[row][col - i].name === "砲" && jumpCounter === -1){
          jumpCounter = 1
          checkCounter = 1
          break
        } else if (chessArray[row][col - i].name !== "" && jumpCounter === 0){
          jumpCounter = -1
        } else if (chessArray[row][col - i].name !== "" && jumpCounter === -1){
          jumpCounter = 0
          break
        }
      }
      jumpCounter = 0
      for (let i = 1; i <= (8 - col); i++){
        console.log(col)
        if (i === 1 && chessArray[row][col + i].name === "卒"){
          checkCounter = 1
          break
        } else if (chessArray[row][col + i].name === "車" && jumpCounter === 0){
          checkCounter = 1
          break
        } else if (chessArray[row][col + i].name === "砲" && jumpCounter === -1){
          jumpCounter = 1
          checkCounter = 1
          break
        } else if (chessArray[row][col + i].name !== "" && jumpCounter === 0){
          jumpCounter = -1
        } else if (chessArray[row][col + i].name !== "" && jumpCounter === -1){
          jumpCounter = 0
          break
        }
      }
      if (chessArray[row - 2][col - 1].name === "馬"){
        if (chessArray[row - 1][col - 1].color === ""){
          checkCounter = 1
        }
      }
      if (chessArray[row - 2][col + 1].name === "馬"){
        if (chessArray[row - 1][col + 1].color === ""){
          checkCounter = 1
        }
      }
      if (row + 2 <= 9){
        if (chessArray[row + 2][col - 1].name === "馬"){
          if (chessArray[row + 1][col - 1].color === ""){
            checkCounter = 1
          }
        }
        if (chessArray[row + 2][col + 1].name === "馬"){
          if (chessArray[row + 1][col + 1].color === ""){
            checkCounter = 1
          }
        }
      }
      if (chessArray[row - 1][col - 2].name === "馬"){
        if (chessArray[row - 1][col - 1].color === ""){
          checkCounter = 1
        }
      }
      if (chessArray[row - 1][col + 2].name === "馬"){
        if (chessArray[row - 1][col + 1].color === ""){
          checkCounter = 1
        }
      }
      if (row + 1 <= 9){
        if (chessArray[row + 1][col - 2].name === "馬"){
          if (chessArray[row + 1][col - 1].color === ""){
            checkCounter = 1
          }
        }
        if (chessArray[row + 1][col + 2].name === "馬"){
          if (chessArray[row + 1][col + 1].color === ""){
            checkCounter = 1
          }
        }
      }
    }
    if (checkCounter > 0){
      dispatch(setCheckReminder(true))
    }
  }

  function clickPlay(rowID: number, colID: number){
    dispatch(play(rowID, colID))
  }

  function clickReset(){
    dispatch(reset())
  }

  function clickGiveUp(){
    dispatch(giveUp())
  }

  function clickWithdrawal(){
    dispatch(withdrawal())
  }

  function clickSetting(){
    dispatch(setSettingMode())
  }

  function clickBack(){
    if (term <= 1 ){return}
    dispatch(showRecordedChessArray(term - 1))
  }

  function clickNext(){
    if (term + 1 > history.length){return}
    dispatch(showRecordedChessArray(term + 1))
  }

  return (
    <div style={{position: 'relative', width: "100%", height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <SideBar 
        gameStart={gameStart}
        term={term}
        currentPlayer={currentPlayer}
        viewMode={viewMode}
        history={history}
        direction={direction}
        hiddenBar={hiddenBar}
        winner={winner}
        onClickBack={clickBack}
        onClickNext={clickNext}
        onClickSetting={()=>dispatch(setSettingMode())}
        chess={"棋"}
      />
      <div className="CCMap">{buildChessDom()}</div>
      {checkReminder === true
      ?<CheckReminder
        currentPlayer={currentPlayer}
        onClickOK={()=>dispatch(setCheckReminder(false))}
      />
      :null
      }
      {gameStart === false || settingMode
      ?<Setting
        title="ChineseChess"
        viewMode={viewMode}
        gameStart={gameStart}
        drawMode={drawMode}
        currentPlayer={currentPlayer}
        term={term}
        winner={winner}
        onClickSetting={clickSetting}
        onClickReset={clickReset}
        onClickMakeRequest={()=>dispatch(makeDrawRequest())}
        onClickGiveUp={clickGiveUp}
        onClickWithdrawal={clickWithdrawal}
      />
      :null
      }
      {drawMode%2 === 1 && drawMode < 6
      ?<GiveUp
        currentPlayer={currentPlayer}
        drawMode={drawMode}
        onClickAccept={()=>dispatch(replyDrawRequest())}
        onClickReject={()=>dispatch(makeDrawRequest())}
      />
      :null
      }
    </div>
  )
}