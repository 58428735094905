import React from 'react';
import './Square.css';

type Props = {
  onSquareClick: (rowID: number, colID:number) => void;
  rowID: number;
  colID: number;
  chessColor: string;
  color: string;
  winDegree: string;
  borderColor: string;
  lines: number;
}

export default function Square(props: Props){

  function click(){
    props.onSquareClick(props.rowID, props.colID)
  }

  let squareWidth = (80/props.lines)

  return (
    <button className="square" style={{background: props.color, border: `solid 2px ${props.borderColor}`, width: `${squareWidth}vmin`, height: `${squareWidth}vmin`}} onClick={()=>click()}>
      <div className="key" style={{background: props.chessColor}}>
      </div>
    </button>)
}