import {createStore, combineReducers, compose, applyMiddleware } from "redux";
import { IFiveChessAction } from "./FiveChess/action";
import { FiveChessReducer } from "./FiveChess/reducer";
import { IFiveChessState } from "./FiveChess/state";
import { IReversiAction } from "./Reversi/action";
import { ReversiReducer } from "./Reversi/reducer";
import { IReversiState } from "./Reversi/state";
import logger from 'redux-logger';
import {
  RouterState,
  connectRouter,
  routerMiddleware,
  CallHistoryMethodAction
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk, { ThunkDispatch } from 'redux-thunk'
import { ILoginState } from "./Login/state";
import { LoginReducer } from "./Login/reducer";
import { ILoginAction } from "./Login/action";
import { IChessState } from "./Chess/state";
import { IChessAction } from "./Chess/action";
import { ChessReducer } from "./Chess/reducer";
import { ICChessState } from "./ChineseChess/state";
import { ICChessAction } from "./ChineseChess/action";
import { CChessReducer } from "./ChineseChess/reducer";

export type IRootThunkDispatch = ThunkDispatch<IRootState, null, IRootAction>

export const history = createBrowserHistory();

export type IRootState = {
  FiveChess: IFiveChessState
  Reversi: IReversiState
  Chess: IChessState
  CChess: ICChessState
  Login: ILoginState
  router: RouterState
}

export type IRootAction = IFiveChessAction | IReversiAction | IChessAction | ICChessAction | ILoginAction | CallHistoryMethodAction;

const rootReducer = combineReducers<IRootState>({
  FiveChess: FiveChessReducer,
  Reversi: ReversiReducer,
  Chess: ChessReducer,
  CChess: CChessReducer,
  Login: LoginReducer,
  router: connectRouter(history)
})

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore<IRootState,IRootAction,{},{}>
  (
    rootReducer, 
    composeEnhancers(
      applyMiddleware(logger),
      applyMiddleware(routerMiddleware(history)),
      applyMiddleware(thunk),
    )
  );
export default store;