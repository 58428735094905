import './App.css';
import { ConnectedRouter, push } from 'connected-react-router';
import { history, IRootState } from '../../redux/store';
import Landing from '../Landing/Landing';


function App() {


  return (
    <ConnectedRouter history={history}>
      <div className="App">
        <Landing />
      </div>
    </ConnectedRouter>
  );
}

export default App;
